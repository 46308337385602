<template>
  <section class="unit-image">
    <div class="unit-image__header fontNum">#{{ image.id }}</div>
    <div class="unit-image__body" :class="bodyClass" @click="selectImage">
      <p class="image" :class="{ 'non-url-type': !existsUrl }">
        <fair-img height="224px" width="224px" :path="url" />
        <period-date
          text="版権期限"
          v-if="image.license_valid_until"
          :date="image.license_valid_until"
          :status="image.license_status"
          :status-master="licenseStatus"
        />
      </p>
      <unit-image-detail :image="image" />
      <status-label v-if="hasFatalError" :is-fatal-error="true" :is-small="true" />
      <status-label
        v-else-if="hasLinkageError"
        :is-linkage-error="true"
        :is-small="true"
        :medias="media"
        :error-media-ids="mediaIdsHasError"
      />
      <inoperable-cover
        v-if="disabled"
        :icon="inoperableIcon"
        :is-vertical="true"
        :text="inoperableText"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import API_CODE from "@/assets/common/ApiCode";
import { WP_WIDTH } from "./image-select";

import FairImg from "@/components/atoms/common/FairImg.vue";
import PeriodDate from "@/components/atoms/common/PeriodDate.vue";
import InoperableCover from "@/components/atoms/common/InoperableCover.vue";
import StatusLabel from "@/components/molecules/common/StatusLabel.vue";
import UnitImageDetail from "./UnitImageDetail.vue";

export default {
  components: {
    FairImg,
    PeriodDate,
    InoperableCover,
    StatusLabel,
    UnitImageDetail,
  },
  props: {
    imageId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      urlType: "",
    };
  },
  computed: {
    ...mapGetters({
      selectedImageId: "selectedImageId",
      enabledMediaIds: "enabledMediaIds",
      enabledCropType: "enabledCropType",
      getImage: "image",
      licenseStatus: "licenseStatus",
      media: "media",
      inoperableCause: "inoperableCause",
    }),
    image() {
      return this.getImage(this.imageId) || {};
    },
    existsUrl() {
      return this.urlType === "" || !!this.image.urls[this.urlType];
    },
    url() {
      const urlType = this.image.urls[this.urlType] ? this.urlType : "thumb";
      return this.image.urls[urlType];
    },
    hasFatalError() {
      return Object.values(this.image.link_statuses).some(
        (status) => status == API_CODE.linkStatus.fatalError
      );
    },
    hasLinkageError() {
      return Object.values(this.image.link_statuses).some(
        (status) => status == API_CODE.linkStatus.error
      );
    },
    mediaIdsHasError() {
      return Object.keys(this.image.link_statuses).filter(
        (mediaId) => this.image.link_statuses[mediaId] >= API_CODE.linkStatus.error
      );
    },
    cause() {
      return this.inoperableCause(this.image, this.enabledMediaIds, this.enabledCropType);
    },
    syncing() {
      const syncingStatus = [API_CODE.linkStatus.inSync, API_CODE.linkStatus.locked];
      return Object.values(this.image.link_statuses).some((status) =>
        syncingStatus.includes(status)
      );
    },
    inoperableIcon() {
      if (this.syncing) {
        return "autorenew";
      }
      return this.disabled ? "warning" : "";
    },
    inoperableText() {
      if (this.syncing) {
        return "媒体とデータを同期中のため<br />選択できません";
      }

      switch (this.cause) {
        case "unlinked":
          return "アルバム連携が<br />有効ではないため<br />選択できません";
        case "untrimmed":
          return "トリミング設定が<br />されていないため<br />選択できません";
        case "too_short":
          return "画像が" + WP_WIDTH + "px未満のため<br />選択できません";
        default:
          return "";
      }
    },
    disabled() {
      return !!this.cause;
    },
    bodyClass() {
      return {
        selected: this.image.id === this.selectedImageId,
        disabled: this.disabled,
      };
    },
  },
  methods: {
    selectImage() {
      if (!this.disabled) {
        this.$emit("select");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.unit-image {
  box-sizing: border-box;
  position: relative;

  &__header {
    color: initial;
    font-size: initial;
    height: $GRID * 1.5;
  }

  &__body {
    @include Box();
    overflow: hidden;
    position: relative;
    width: $GRID * 14;
    cursor: pointer;
    transition: all 400ms ease-in;

    &:not(.disabled):not(.selected) {
      &:hover {
        box-shadow: $DEPTH_LV_3;
        transform: scale(1.05);
        transition: transform 160ms ease-out;
      }
    }
    &.disabled {
      cursor: not-allowed;
    }
    &.selected {
      box-shadow: 0 0 0 $GRID * 0.25 rgba($COLOR_SUBMIT, 0.85);
      cursor: default;
    }
  }

  .image {
    position: relative;

    &.non-url-type {
      background: rgba(#000, 0.5);
      ::v-deep img {
        opacity: 0.4;
      }
    }
  }
}
</style>
