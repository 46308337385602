<template>
  <container-benefit-publish
    :has-changed="hasChanged"
    :is-all-valid="isAllValid"
    @submit="onClick"
  >
    <draggable-template-list :media-id="mediaId" :benefit-type="benefitType" />
  </container-benefit-publish>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import API_CODE from "@/assets/common/ApiCode";
import HALL_BENEFIT_TYPE from "@/assets/benefit/hall-benefit-type";

import ContainerBenefitPublish from "@/components/benefit/hall-benefit-publish/ContainerBenefitPublish.vue";
import DraggableTemplateList from "@/components/benefit/hall-benefit-publish/DraggableTemplateList.vue";

export default {
  components: {
    ContainerBenefitPublish,
    DraggableTemplateList,
  },
  data() {
    return {
      mediaId: API_CODE.media.mynavi,
      benefitType: HALL_BENEFIT_TYPE.common,
      initialized: false,
    };
  },
  computed: {
    ...mapGetters({
      $hasChanged: "publishing/hasChanged",
      isAllValid: "publishing/isAllValid",
    }),
    hasChanged() {
      return (
        this.initialized && this.$hasChanged(this.mediaId, this.benefitType)
      );
    },
  },
  mounted() {
    this.initialized = true;
  },
  methods: {
    ...mapActions({
      putHallBenefits: "publishing/putHallBenefits",
    }),
    onClick() {
      const mediaId = this.mediaId;
      const benefitType = this.benefitType;
      this.putHallBenefits({ mediaId, benefitType });
    },
  },
};
</script>
