<template>
  <benefit-form :note="note" :option-level="optionLevel([keyName])" title="相当金額">
    <input-item>
      <validating-textarea
        v-model="value"
        :is-full-size="true"
        :is-required="isRequired(keyName)"
        :max-length="maxCharacters(keyName, mediaId)"
        :ng-word="ngWord(keyName)"
        @check="validatePriceNote"
      />
    </input-item>
  </benefit-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import * as types from "@/store/benefit/mutationTypes";

import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import InputItem from "@/components/benefit/inputs/InputItem.vue";
import ValidatingTextarea from "@/components/molecules/common/ValidatingTextarea";

export default {
  components: {
    BenefitForm,
    InputItem,
    ValidatingTextarea,
  },
  data() {
    return {
      keyName: "price_notes",
      mediaId: API_CODE.media.mw,
    };
  },
  computed: {
    ...mapGetters({
      optionLevel: "optionLevel",
      needNote: "needNote",
      onlyMediaName: "onlyMediaName",
      ngWord: "ngWord",
      isRequired: "rules/isRequired",
      maxCharacters: "rules/maxCharacters",
      priceNote: "priceNotes",
    }),
    note() {
      if (this.needNote(this.keyName)) {
        return this.onlyMediaName(this.keyName);
      }
      return null;
    },
    value: {
      get() {
        return this.priceNote(this.mediaId);
      },
      set(note) {
        const mediaId = this.mediaId;
        this.updatePriceNote({ mediaId, note });
      },
    },
  },
  beforeUnmount() {
    this.validatePriceNote(true);
  },
  methods: {
    ...mapMutations({
      updatePriceNote: `${types.PRICE_NOTES}`,
      $validatePriceNote: `validation/${types.PRICE_NOTES}`,
    }),
    validatePriceNote(isValid) {
      const mediaId = this.mediaId;
      this.$validatePriceNote({ mediaId, isValid });
    },
  },
};
</script>
