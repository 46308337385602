<template>
  <benefit-form :title="title" :note="note" :option-level="optionLevel([keyName])">
    <by-media-input :title="title" :is-by-media="isByMedia" @toggleByMedia="isByMedia = !isByMedia">
      <input-item
        v-for="mediaId in targetMedia"
        :media-name="mediaShortName(mediaId)"
        :is-by-media="mediaId !== commonId"
        :key="mediaId"
      >
        <validating-textarea
          :model-value="notes(mediaId)"
          :is-full-size="true"
          :is-required="isRequired(keyName)"
          :is-textarea="true"
          :max-length="maxCharacters(keyName, mediaId)"
          :media-ids="[mediaId]"
          :ng-word="ngWord(keyName)"
          :rows="5"
          :full-width="fullWidth(mediaId)"
          @update:modelValue="updateNotes(mediaId, $event)"
          @check="validateNotes(mediaId, $event)"
        />
      </input-item>
    </by-media-input>
  </benefit-form>
</template>

<script>
import API_CODE from "@/assets/common/ApiCode";

import * as types from "@/store/benefit/mutationTypes";

import ValidatingTextarea from "@/components/molecules/common/ValidatingTextarea";
import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import ByMediaInput from "@/components/benefit/presentation/ByMediaInput.vue";
import InputItem from "@/components/benefit/inputs/InputItem.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    ValidatingTextarea,
    BenefitForm,
    ByMediaInput,
    InputItem,
  },
  data() {
    return {
      commonId: API_CODE.mediaCommon,
      title: "備考/利用条件",
      keyName: "notes",
    };
  },
  computed: {
    ...mapGetters({
      groupType: "groupType",
      isByMedium: "isByMedium",
      mediaShortName: "mediaShortName",
      needNote: "needNote",
      ngWord: "ngWord",
      notes: "notes",
      onlyMediaName: "onlyMediaName",
      optionLevel: "optionLevel",
      $selectedOnlyMedia: "selectedOnlyMedia",
      isRequired: "rules/isRequired",
      maxCharacters: "rules/maxCharacters",
      $onlyMedia: "rules/onlyMedia",
    }),
    isByMedia: {
      get() {
        return this.isByMedium(this.keyName);
      },
      set(value) {
        this.setIsByMedia({ [this.keyName]: value });
        const targetMedia = value ? [API_CODE.mediaCommon] : this.onlyMedia;
        this.resetMediaValidation(targetMedia);
      },
    },
    note() {
      if (this.needNote(this.keyName)) {
        return this.onlyMediaName(this.keyName);
      }
      return null;
    },
    onlyMedia() {
      return this.$onlyMedia(this.keyName);
    },
    selectedOnlyMedia() {
      return this.$selectedOnlyMedia(this.keyName);
    },
    targetMedia() {
      return this.isByMedia ? this.selectedOnlyMedia : [API_CODE.mediaCommon];
    },
  },
  beforeUnmount() {
    const targetMedia = [...this.onlyMedia, API_CODE.mediaCommon];
    this.resetMediaValidation(targetMedia);
  },
  methods: {
    ...mapActions({
      setIsByMedia: "changeIsByMedia",
    }),
    ...mapMutations({
      $updateNotes: `${types.NOTES}`,
      $validateNotes: `validation/${types.NOTES}`,
    }),
    updateNotes(mediaId, note) {
      this.$updateNotes({ mediaId, note });
    },
    validateNotes(mediaId, isValid) {
      this.$validateNotes({ mediaId, isValid });
    },
    resetMediaValidation(targetMedia) {
      const validateNotes = this.validateNotes;
      targetMedia.forEach((mediaId) => validateNotes(mediaId, true));
    },
    fullWidth(mediaId) {
      return mediaId !== API_CODE.media.zexy;
    },
  },
};
</script>
