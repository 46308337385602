import { getCookieAccount } from "@/lib/cookie";

import Repository from "./repository";

export default () => {
  const { token } = getCookieAccount();
  const resource = "news";
  const { index, show, store, update, destroy } = Repository(`${resource}`, token);
  return {
    index,
    store,
    update,
    destroy,
    showPublishedNews: () => show("published"),
  };
};
