<template>
  <modal-header-window
    icon="swap_horiz"
    :title="title"
    :is-set-footer="false"
    :is-small-title="true"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <ul class="content">
        <li
          v-for="(name, index) in mediaList"
          :key="index"
          class="content-item"
        >
          {{ name }}
        </li>
      </ul>
    </template>
  </modal-header-window>
</template>

<script>
import ModalHeaderWindow from "@/components/molecules/common/ModalHeaderWindow.vue";

export default {
  components: {
    ModalHeaderWindow,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    mediaList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .modalHeaderWindow {
  width: $GRID * 20;

  &_icon {
    font-size: 16px;
  }
}

.content {
  padding-left: $GRID;
}

.content-item + .content-item {
  margin-top: $SUB_GRID;
}
</style>
