<template>
  <div class="replace-form">
    <div v-if="!needShowConfirmForm" class="replace-form-before">
      <p class="title">特典の一括変更</p>
      <p class="title-note">
        適用期限切れや使用をやめたい特典などを
        <br />一括で解除したり差し替えたりすることができます
      </p>
      <div class="button-block">
        <action-button class="button" text="一括解除する" :is-alert="true" @clickHandler="unlink" />
        <action-button
          class="button"
          text="特典を一括で差し替える"
          @clickHandler="needModalSelect = true"
        />
      </div>
    </div>
    <div v-else class="replace-form-after">
      <p class="title">特典の一括変更</p>
      <p class="title-note">以下の特典に変更します</p>
      <div class="content">
        <fair-benefit-unit class="unit" mode="select" :data="value" :no-hover-style="true" />
        <p class="arrow">→</p>
        <fair-benefit-unit
          class="unit"
          mode="select"
          :data="selectedBenefit"
          :no-hover-style="true"
        />
        <div class="button-block">
          <action-button
            class="button"
            text="特典を選択する"
            :is-large="true"
            @clickHandler="needModalSelect = true"
          />
          <action-button
            class="button"
            text="変更する"
            :is-disabled="value.id === selectedBenefit.id"
            :is-save="true"
            :is-large="true"
            @clickHandler="replace"
          />
        </div>
      </div>
    </div>
    <modal-select
      v-if="needModalSelect"
      v-model="selectedBenefit"
      @close="needModalSelect = false"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import ActionButton from "@/components/atoms/common/ActionButton.vue";
import FairBenefitUnit from "@/components/benefit/benefit-list/FairBenefitUnit.vue";
import ModalSelect from "@/components/modals/ModalSelect.vue";

export default {
  components: {
    ActionButton,
    FairBenefitUnit,
    ModalSelect,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedData: null,
      needModalSelect: false,
      needShowConfirmForm: false,
    };
  },
  computed: {
    selectedBenefit: {
      get() {
        if (this.selectedData == null) return this.value;
        return this.selectedData;
      },
      set(value) {
        this.selectedData = value;
        this.needShowConfirmForm = true;
        this.$emit("select");
      },
    },
  },
  methods: {
    ...mapActions({
      deleteLinkedFairs: "deleteLinkedFairs",
      replaceLinkedFairs: "replaceLinkedFairs",
    }),
    async unlink() {
      const param = {
        benefit: this.value,
      };
      const isSuccess = await this.deleteLinkedFairs(param);
      if (isSuccess) this.$emit("close");
    },
    async replace() {
      const param = {
        oldBenefit: this.value,
        newBenefit: this.selectedData,
      };
      const isSuccess = await this.replaceLinkedFairs(param);
      if (isSuccess) this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.replace-form {
  @include Box();
  background: $BG_COLOR_SUB;
  padding: $GRID * 1.5;

  .button {
    font-size: 12px;
    height: $GRID * 2;
    width: $GRID * 13;
    padding: 0 !important;
  }

  .title {
    color: $COLOR_KEY;
    font-size: $FONT_SIZE_M;
    font-weight: bold;
    margin-bottom: $SUB_GRID;

    &-note {
      color: $COLOR_SUB_TEXT;
      font-size: 14px;
      margin-bottom: $GRID * 2;
    }
  }

  &-before {
    .button-block {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .button + .button {
      margin-left: $GRID;
    }
  }

  &-after {
    min-height: $GRID * 17;

    .content {
      display: flex;
    }

    .arrow {
      margin: auto $GRID * 0.5;
    }

    .unit {
      margin: 0;
      width: $GRID * 22;
    }

    .button-block {
      margin: auto 0 0 $GRID;
    }

    .button {
      width: $GRID * 10;
    }

    .button + .button {
      margin-top: $GRID;
    }
  }
}
</style>
