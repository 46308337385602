import { createRouter, createWebHistory } from "vue-router";
import BenefitView from "@/views/BenefitView.vue";
import ErrorView from "@/views/ErrorView.vue";

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      name: "benefit",
      path: "/benefit",
      component: BenefitView,
    },
    {
      name: "error",
      path: "/:pathMatch(.*)*",
      component: ErrorView,
    },
  ],
});
