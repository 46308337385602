<template>
  <benefit-form title="リンクタイトル" :note="note" :option-level="isRequired ? 2 : 1">
    <input-item>
      <validating-textarea
        v-model="value"
        :is-full-size="true"
        :is-required="isRequired"
        :max-length="maxCharacters(keyName, mediaId)"
        :media-ids="[mediaId]"
        @check="validateLinkTitle"
      />
    </input-item>
  </benefit-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import * as types from "@/store/benefit/mutationTypes";

import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import InputItem from "@/components/benefit/inputs/InputItem.vue";
import ValidatingTextarea from "@/components/molecules/common/ValidatingTextarea";

export default {
  components: {
    BenefitForm,
    InputItem,
    ValidatingTextarea,
  },
  data() {
    return {
      keyName: "link_title",
      mediaId: API_CODE.media.mynavi,
    };
  },
  computed: {
    ...mapGetters({
      needNote: "needNote",
      onlyMediaName: "onlyMediaName",
      isRequired: "requiredMynaviLink",
      maxCharacters: "rules/maxCharacters",
      linkTitle: "linkTitle",
    }),
    note() {
      if (this.needNote(this.keyName)) {
        return this.onlyMediaName(this.keyName);
      }
      return null;
    },
    value: {
      get() {
        return this.linkTitle(this.mediaId);
      },
      set(title) {
        const mediaId = this.mediaId;
        this.updateLinkTitle({ mediaId, title });
      },
    },
  },
  beforeUnmount() {
    this.validateLinkTitle(true);
  },
  methods: {
    ...mapMutations({
      updateLinkTitle: `${types.LINK_TITLE}`,
      $validateLinkTitle: `validation/${types.LINK_TITLE}`,
    }),
    validateLinkTitle(isValid) {
      const mediaId = this.mediaId;
      this.$validateLinkTitle({ mediaId, isValid });
    },
  },
};
</script>
