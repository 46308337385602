import { getCookieAccount } from "@/lib/cookie";

import Repository, { createAxiosInstance } from "./repository";

export default () => {
  const { hall_id, token, userable_id } = getCookieAccount();
  const hallId = hall_id || userable_id;
  const resource = "media-accounts";
  const { instance, index, show, patch } = Repository(`${hallId}/${resource}`, token);
  return {
    index,
    show,
    patch,
    connectToMedia: (payload) => instance.put("connect", payload),
    getTargetMedia: async (targetHallId) => {
      const axios = createAxiosInstance(token);
      const response = await axios.get(`${targetHallId}/${resource}`);
      return response;
    },
  };
};
