/**
 * コレモネっと設定画面の値を各画面で使用するためのモジュール
 */
import API_CODE from "@/assets/common/ApiCode";

import useHpSettingRepository from "@/repositories/hp-setting";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";

const hpSettingModule = {
  state,
  getters,
  mutations,
  actions: {
    fetchSetting: async ({ commit }) => {
      const { showDataSetting } = useHpSettingRepository();
      const response = await showDataSetting();

      // 取得に失敗したらエラーを投げる
      if (!response || response.status !== API_CODE.response.success) {
        throw {
          message: "データの取得に失敗しました。",
        };
      }

      const { data } = response;
      commit("setState", data);
    },
  },
  namespaced: true,
};

export default hpSettingModule;
