<template>
  <benefit-mw :benefit-type="benefitType" />
</template>

<script>
import HALL_BENEFIT_TYPE from "@/assets/benefit/hall-benefit-type";

import BenefitMw from "./BenefitMw.vue";

export default {
  components: {
    BenefitMw,
  },
  data() {
    return {
      benefitType: HALL_BENEFIT_TYPE.contract,
    };
  },
};
</script>
