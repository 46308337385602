import VueScrollTo from "vue-scrollto";
import { mapActions, mapState } from "vuex";

/**
 * CAUTION:
 *  - mixinに追加できる関数は、BenefitListZexyとBenefitListWpで完全に一致する処理だけとする
 *  - groupTypeによるifが発生する場合や、片方のコンポーネントだけで必要な処理はmixinに追加しない
 *  - componentsとpropsについてはコンポーネントに強く結びついているものなのでmixinとして共通化しない
 */
const benefitListColumn = {
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    ...mapState(["anchor", "isLoadEdit", "selectedData"]),
    ...mapState("modal", {
      completeModal: (state) => state.completeModal,
    }),
    ...mapState("ngCheck", { loadingNg: (state) => state.loading }),
  },
  watch: {
    completeModal: {
      deep: true,
      handler(newValue) {
        // 完了メッセージ表示後
        if (newValue.isShow === true) this.close();
      },
    },
  },
  methods: {
    ...mapActions({
      updateLoadEdit: "updateLoadEdit",
      saveData: "saveData",
      setSelectedData: "setSelectedData",
      setTargetId: "setTargetId",
    }),
    setEditingId(benefitId) {
      this.$emit("updateEditingId", benefitId);
      this.editing = false;
    },
    copyBenefit() {
      this.editing = true;
    },
    close() {
      // 特典の新規登録または編集完了時にモーダルを閉じると、一覧の最上部にスクロールする
      const addingBenefit = this.editing && this.editingId === null;
      const succeedUpdateBenefit =
        this.completeModal.isShow && this.editingId !== null;
      if (addingBenefit || succeedUpdateBenefit) {
        VueScrollTo.scrollTo("#scrollTop");
      }

      this.setEditingId(null);
    },
    isEdit(benefitId) {
      if (this.isLoadEdit && this.anchor.id == benefitId) {
        // 編集モードでアクセスしてきた場合は編集画面にしておく
        this.setEditingId(this.anchor.id);
        this.updateLoadEdit(false);
      }
      return this.editingId == benefitId;
    },
  },
};

export default benefitListColumn;
