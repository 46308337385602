import { getCookieAccount } from "@/lib/cookie";

import Repository from "./repository";

export default () => {
  const { hall_id, token, userable_id } = getCookieAccount();
  const hallId = hall_id || userable_id;
  const resource = "hall-benefits";
  const { index, update } = Repository(`${hallId}/${resource}`, token);
  return { index, update };
};
