<template>
  <benefit-mw />
</template>

<script>
import BenefitMw from "./BenefitMw.vue";

export default {
  components: {
    BenefitMw,
  },
};
</script>
