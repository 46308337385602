<template>
  <div id="scrollTop" class="benefit-list-container">
    <benefit-list-zexy
      class="benefit-list-zexy"
      :editing-id="editingId"
      @replace="openReplaceModal"
      @updateEditingId="setEditingId"
    />
    <benefit-list-wp
      class="benefit-list-wp"
      :editing-id="editingId"
      @replace="openReplaceModal"
      @updateEditingId="setEditingId"
    />
    <modal-replace
      v-if="needShowReplaceModal"
      :used-contents="{ fairs: replaceTarget.used_fairs }"
      :need-show-used="needShowUsed"
      @close="closeModal"
    >
      <template #replaceContent>
        <replace-form :value="replaceTarget" @select="needShowUsed = false" @close="closeModal" />
      </template>
    </modal-replace>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AddButton from "@/components/atoms/common/AddButton.vue";
import ModalReplace from "@/components/molecules/common/ModalReplace.vue";
import BenefitListWp from "@/components/benefit/benefit-list/BenefitListWp.vue";
import BenefitListZexy from "@/components/benefit/benefit-list/BenefitListZexy.vue";
import ReplaceForm from "@/components/benefit/benefit-list/ReplaceForm.vue";

export default {
  components: {
    AddButton,
    ModalReplace,
    BenefitListWp,
    BenefitListZexy,
    ReplaceForm,
  },
  data() {
    return {
      needShowUsed: true,
      needShowReplaceModal: false,
      replaceTarget: null,
      editingId: null,
    };
  },
  computed: {
    ...mapGetters({
      benefitItem: "benefitItem",
    }),
  },
  created() {
    this.fetchImages();
    this.fetchTags();
    this.fetchMediaAccounts();
  },
  methods: {
    ...mapActions({
      setTargetId: "setTargetId",
      fetchImages: "imageSelect/fetchImages",
      fetchTags: "imageSelect/fetchTags",
      fetchMediaAccounts: "imageSelect/fetchMediaAccounts",
    }),
    openReplaceModal(selectId) {
      this.needShowUsed = true;
      this.needShowReplaceModal = true;
      this.replaceTarget = this.benefitItem(selectId);
    },
    closeModal() {
      this.needShowReplaceModal = false;
      this.replaceTarget = null;
    },
    setEditingId(benefitId) {
      this.editingId = benefitId;
      this.setTargetId(benefitId);
    },
  },
};
</script>

<style scoped lang="scss">
.benefit-list-container {
  box-shadow: $DEPTH_LV_0, $DEPTH_TOP_LV_2;
  position: relative;
  display: flex;
  margin-top: $GRID;
}

.benefit-list-zexy {
  width: 50%;
  background-color: $BG_COLOR_BOX;
}

.benefit-list-wp {
  width: 50%;
  background-color: $BG_COLOR_SUB;
  border-left: 1px solid #e2e2e2;
}
</style>
