<template>
  <section class="draggable-template-list">
    <h3 class="header">
      <p>{{ valueLabel }}</p>
      <p v-if="hasError" class="header__error">
        <i class="material-icons">error</i>
        <span>公開設定の反映に失敗しました</span>
      </p>
    </h3>
    <draggable v-model="templateList" itemKey="orderNum" handle=".benefit-publish-unit">
      <template #item="{ element: template, index }">
        <div class="benefit-unit">
          <p v-if="maxNum > 1" class="fontNum">{{ template.orderNum }}</p>
          <benefit-publish-unit
            :index="index"
            :benefit-type="benefitType"
            :media-id="mediaId"
            class="benefit-publish-unit"
          />
        </div>
      </template>
    </draggable>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import draggable from "vuedraggable";
import BenefitPublishUnit from "@/components/benefit/hall-benefit-publish/BenefitPublishUnit.vue";

export default {
  components: {
    draggable,
    BenefitPublishUnit,
  },
  props: {
    mediaId: {
      type: Number,
      required: true,
    },
    benefitType: {
      type: String,
      required: true,
    },
    valueLabel: {
      type: String,
      default: "掲載する特典",
    },
  },
  computed: {
    ...mapGetters({
      templateListByBenefitType: "publishing/templateListByBenefitType",
      maxRegistrableNum: "publishing/maxRegistrableNum",
      linkStatus: "publishing/linkStatus",
    }),
    templateList: {
      get() {
        const templateList = this.templateListByBenefitType(this.benefitType);
        return [...Array(this.maxNum)].map((_, index) => {
          const template = templateList[index] || {
            publishedTemplate: null,
            scheduledTemplate: null,
          };
          return { ...template, orderNum: index + 1 };
        });
      },
      set(templateList) {
        const benefitType = this.benefitType;
        this.updateTemplateList({ benefitType, templateList });
      },
    },
    maxNum() {
      return this.maxRegistrableNum(this.mediaId, this.benefitType);
    },
    status() {
      return this.linkStatus(this.mediaId, this.benefitType);
    },
    hasError() {
      const errorStatus = [API_CODE.linkStatus.error, API_CODE.linkStatus.fatalError];
      return errorStatus.includes(this.status);
    },
  },
  mounted() {
    const mediaId = this.mediaId;
    const benefitType = this.benefitType;
    this.setupTemplateList({ mediaId, benefitType });
  },
  beforeUnmount() {
    this.resetTemplateList();
  },
  methods: {
    ...mapActions({
      setupTemplateList: "publishing/setupTemplateList",
      resetTemplateList: "publishing/resetTemplateList",
    }),
    ...mapMutations({
      updateTemplateList: "publishing/templateList",
    }),
  },
};
</script>

<style lang="scss" scoped>
.draggable-template-list {
  .header {
    display: flex;
    align-items: center;
    gap: $SUB_GRID;
    margin-bottom: $GRID;
    color: $COLOR_KEY;
    font-size: 14px;
    line-height: 14px;

    &__error {
      display: flex;
      align-items: center;
      gap: $SUB_GRID;
      height: $GRID * 2;
      padding: 0 ($GRID * 0.5);
      background-color: $BG_COLOR_ALERT;
      border-radius: $GRID * 0.5;
      color: $COLOR_ALERT;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .benefit-unit {
    display: flex;
    align-items: center;
    gap: $GRID;
    width: 100%;
    box-sizing: border-box;
    padding-left: $GRID * 0.5;
  }

  .benefit-unit + .benefit-unit {
    margin-top: $GRID * 0.5;
  }

  .benefit-publish-unit {
    cursor: pointer;
    flex: 1;
  }
}
</style>
