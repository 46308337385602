<template>
  <section class="publish-unit">
    <benefit-publish-detail
      v-model="publishedPeriod"
      :media-id="mediaId"
      :benefit-id="publishedId"
      :type="publishedTemplate ? publishedTemplate.type : null"
      :titles="publishedTemplate ? publishedTemplate.title : null"
      :notes-list="publishedTemplate ? publishedTemplate.notes : null"
      :has-until-warning="hasPeriodWarning"
      :published="true"
      @change="showSelectModal(true)"
      @clear="changeBenefit(null, true)"
    />
    <div class="publish-unit__arrow">
      <i class="material-icons">arrow_right_alt</i>
    </div>
    <benefit-publish-detail
      v-model="scheduledPeriod"
      :media-id="mediaId"
      :benefit-id="scheduledId"
      :type="scheduledTemplate ? scheduledTemplate.type : null"
      :titles="scheduledTemplate ? scheduledTemplate.title : null"
      :notes-list="scheduledTemplate ? scheduledTemplate.notes : null"
      :has-from-warning="hasPeriodWarning"
      @change="showSelectModal(false)"
      @clear="changeBenefit(null, false)"
    />
  </section>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import { addDays, startOfToday, isBefore, isEqual, parseISO } from "date-fns";

import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";
import BENEFIT_TYPE from "@/assets/common/benefit-type";
import HALL_BENEFIT_TYPE from "@/assets/benefit/hall-benefit-type";

import isEarlierDate from "@/lib/isEarlierDate";
import modal from "@/lib/modal";

import store from "@/store/benefit";

import BenefitPublishDetail from "@/components/benefit/hall-benefit-publish/BenefitPublishDetail.vue";
import ModalHallBenefitSelect from "@/components/benefit/hall-benefit-publish/modal-benefit-select/ModalHallBenefitSelect.vue";

export default {
  components: {
    BenefitPublishDetail,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    benefitType: {
      type: String,
      default: HALL_BENEFIT_TYPE.visit,
    },
    mediaId: {
      type: Number,
      default: API_CODE.media.zexy,
    },
  },
  computed: {
    ...mapGetters({
      benefits: "benefits",
      $publishedTemplate: "publishing/publishedTemplate",
      $publishedPeriod: "publishing/publishedPeriod",
      $scheduledTemplate: "publishing/scheduledTemplate",
      $scheduledPeriod: "publishing/scheduledPeriod",
      flattedTemplateList: "publishing/flattedTemplateList",
    }),
    publishedPeriod: {
      get() {
        return this.$publishedPeriod(
          this.index,
          this.benefitType,
          this.mediaId
        );
      },
      set({ from, until }) {
        const payload = {
          from,
          until,
          benefitType: this.benefitType,
          targetIndex: this.index,
          mediaId: this.mediaId,
        };
        this.updatePublishedPeriod(payload);
      },
    },
    scheduledPeriod: {
      get() {
        return this.$scheduledPeriod(
          this.index,
          this.benefitType,
          this.mediaId
        );
      },
      set({ from, until }) {
        const payload = {
          from,
          until,
          benefitType: this.benefitType,
          targetIndex: this.index,
          mediaId: this.mediaId,
        };
        this.updateScheduledPeriod(payload);
      },
    },
    publishedTemplate() {
      return this.$publishedTemplate(this.index, this.benefitType);
    },
    publishedId() {
      return this.publishedTemplate ? this.publishedTemplate.id : null;
    },
    scheduledTemplate() {
      return this.$scheduledTemplate(this.index, this.benefitType);
    },
    scheduledId() {
      return this.scheduledTemplate ? this.scheduledTemplate.id : null;
    },
    hasPeriodWarning() {
      const publishedUntil = this.publishedPeriod.until;
      const scheduledFrom = this.scheduledPeriod.from;

      if (publishedUntil === null || scheduledFrom === null) {
        return false;
      }

      const until = parseISO(publishedUntil);
      const from = parseISO(scheduledFrom);
      return isBefore(from, until) || isEqual(from, until);
    },
  },
  methods: {
    ...mapMutations({
      updatePublishedTemplate: "publishing/publishedTemplate",
      updatePublishedPeriod: "publishing/publishedPeriod",
      updateScheduledTemplate: "publishing/scheduledTemplate",
      updateScheduledPeriod: "publishing/scheduledPeriod",
    }),
    filterBenefits(published) {
      const benefits = this.benefits[BENEFIT_GROUP_TYPE.hall];
      const selectedId = published ? this.publishedId : this.scheduledId;
      const templateList = this.flattedTemplateList(this.benefitType).filter(
        ({ id }) => id !== selectedId
      );
      return benefits.filter((benefit) => {
        // 掲載媒体に指定の媒体が含まれているかどうか
        const hasMedia = benefit.media_ids.includes(this.mediaId);

        // 掲載中の特典を選択する場合は、掲載開始日が今日以前のものだけにする
        const publicationFrom = benefit.publication_from;
        const from =
          publicationFrom[this.mediaId] ||
          publicationFrom[API_CODE.mediaCommon];
        const today = startOfToday();
        const date = addDays(today, 1);
        const periodWithin = published ? isEarlierDate(from, date) : true;

        // 同媒体の他の掲載枠で使用されているかどうか
        const inUsed = templateList.some(({ id }) => id === benefit.id);

        const matchedCondition = hasMedia && periodWithin && !inUsed;

        // 特典種別が共通の場合は、種別の判定を実施しない
        if (this.benefitType === HALL_BENEFIT_TYPE.common) {
          return matchedCondition;
        }

        // 特典種別が指定の種別であるかどうか
        const type = benefit.type[API_CODE.mediaCommon];
        const targetType = BENEFIT_TYPE[this.benefitType];
        return matchedCondition && type === targetType;
      });
    },
    async showSelectModal(published) {
      const benefit = published
        ? this.publishedTemplate
        : this.scheduledTemplate;
      const benefits = this.filterBenefits(published);
      const props = { benefit, benefits, mediaId: this.mediaId };
      const benefitSelectModal = modal(ModalHallBenefitSelect, props, store);
      benefitSelectModal.then((changedBenefit) => {
        this.changeBenefit(changedBenefit, published);
      });
    },
    changeBenefit(newTemplate, published) {
      const payload = {
        newTemplate,
        targetIndex: this.index,
        benefitType: this.benefitType,
      };

      if (published) {
        this.updatePublishedTemplate(payload);
      } else {
        this.updateScheduledTemplate(payload);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.publish-unit {
  @include Box();
  display: flex;
  min-height: $GRID * 7.5;

  &__arrow {
    display: flex;
    align-items: center;
    padding: 0 $SUB_GRID;
    border-left: 1px solid $BG_COLOR_SUB;
    border-right: 1px solid $BG_COLOR_SUB;
  }

  .material-icons {
    color: $COLOR_SUB_TEXT;
    font-size: 40px;
    font-weight: bold;
  }
}
</style>
