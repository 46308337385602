<template>
  <section class="benefit-edit">
    <form-media />
    <form-type />
    <form-benefit-detail-type v-if="showDetailType" />
    <form-discount-type v-if="showDiscountType" />
    <form-title v-if="showInput('title')" />
    <form-description v-if="showInput('description')" title="説明文/特典詳細" />
    <form-notes v-if="showInput('notes')" />
    <form-publication-date v-if="showInput('publication_from')" />
    <form-valid-period-zexy v-if="showInput('valid_period_text')" />
    <form-valid-period-mw v-if="showValidPeriodMw" />
    <form-image />
    <form-highlight v-if="showHighlight" />
    <form-price-notes v-if="showPriceNotes" />
    <form-media-only v-if="showInput('is_media_only')" />
    <form-link-title v-if="showInput('link_title')" />
    <form-url v-if="showInput('link_url')" />
    <div class="benefit-edit__buttons">
      <action-button
        :is-cancel="true"
        text="キャンセル"
        @clickHandler="$emit('close')"
      />
      <action-button
        :is-disabled="isInvalid"
        :is-save="true"
        class="benefit-edit__buttons-save"
        text="保存する"
        @clickHandler="save"
      />
    </div>
  </section>
</template>

<script>
import ActionButton from "@/components/atoms/common/ActionButton";
import FormMedia from "@/components/benefit/forms/common/FormMedia.vue";
import FormType from "@/components/benefit/forms/common/FormType.vue";
import FormBenefitDetailType from "@/components/benefit/forms/hall-benefit/FormBenefitDetailType.vue";
import FormDiscountType from "@/components/benefit/forms/hall-benefit/FormDiscountType.vue";
import FormTitle from "@/components/benefit/forms/common/FormTitle.vue";
import FormDescription from "@/components/benefit/forms/common/FormDescription.vue";
import FormNotes from "@/components/benefit/forms/hall-benefit/FormNotes.vue";
import FormPublicationDate from "@/components/benefit/forms/hall-benefit/FormPublicationDate.vue";
import FormValidPeriodZexy from "@/components/benefit/forms/hall-benefit/FormValidPeriodZexy.vue";
import FormValidPeriodMw from "@/components/benefit/forms/hall-benefit/FormValidPeriodMw.vue";
import FormImage from "@/components/benefit/forms/common/FormImage.vue";
import FormHighlight from "@/components/benefit/forms/hall-benefit/FormHighlight.vue";
import FormPriceNotes from "@/components/benefit/forms/hall-benefit/FormPriceNotes.vue";
import FormMediaOnly from "@/components/benefit/forms/common/FormMediaOnly.vue";
import FormLinkTitle from "@/components/benefit/forms/hall-benefit/FormLinkTitle.vue";
import FormUrl from "@/components/benefit/forms/hall-benefit/FormUrl.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ActionButton,
    FormMedia,
    FormType,
    FormBenefitDetailType,
    FormDiscountType,
    FormTitle,
    FormDescription,
    FormNotes,
    FormPublicationDate,
    FormValidPeriodZexy,
    FormValidPeriodMw,
    FormImage,
    FormHighlight,
    FormPriceNotes,
    FormMediaOnly,
    FormLinkTitle,
    FormUrl,
  },
  computed: {
    ...mapGetters({
      mediaIds: "mediaIds",
      showInput: "showInput",
      isContract: "isContract",
      isAllValid: "validation/isAllValid",
    }),
    isInvalid() {
      return this.mediaIds.length < 1 || !this.isAllValid;
    },
    showHighlight() {
      return this.showInput("is_highlighted");
    },
    showValidPeriodMw() {
      return this.isContract && this.showInput("application_from");
    },
    showDetailType() {
      return this.isContract && this.showInput("detail_type");
    },
    showDiscountType() {
      return this.isContract && this.showInput("discount_type");
    },
    showPriceNotes() {
      return this.showInput("price_notes");
    },
  },
  methods: {
    ...mapActions({ save: "saveData" }),
  },
};
</script>

<style scoped lang="scss">
.benefit-edit {
  &__buttons {
    display: flex;
    justify-content: flex-end;
    padding: $GRID * 2 $GRID $GRID;

    &-save {
      margin-left: $GRID;
      width: $GRID * 11;
    }
  }
}
</style>
