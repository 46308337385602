<template>
  <modal-header-window icon="swap_horiz" title="変更" @close="$emit('cancel')">
    <template v-slot:content>
      <filter-block title="特典" class="hall-benefit-filter">
        <template #filter-l>
          <filter-input name="keyword" placeholder="ID・特典名・特典内容・備考" @update="filter" />
        </template>
      </filter-block>
      <section class="hall-benefit-select">
        <hall-benefit-unit
          v-for="benefit in filteredBenefits"
          :data="benefit"
          :editable="false"
          :selectable="true"
          :selected="benefit.id === selectedBenefitId"
          :key="benefit.id"
          class="benefit-unit"
          @onClick="select(benefit)"
        />
      </section>
    </template>
    <template v-slot:footer>
      <action-button
        text="選択する"
        :is-save="true"
        @clickHandler="$emit('submit', selectedBenefit)"
      />
    </template>
  </modal-header-window>
</template>

<script>
import { isBefore, parseISO } from "date-fns";

import API_CODE from "@/assets/common/ApiCode";

import deepCopy from "@/lib/deepCopy";

import filter from "@/store/benefit/filterModule";

import ActionButton from "@/components/atoms/common/ActionButton.vue";
import FilterInput from "@/components/atoms/common/FilterInput.vue";
import FilterBlock from "@/components/molecules/common/FilterBlock.vue";
import ModalHeaderWindow from "@/components/molecules/common/ModalHeaderWindow.vue";
import HallBenefitUnit from "@/components/benefit/benefit-list/HallBenefitUnit.vue";

export default {
  components: {
    ActionButton,
    FilterInput,
    FilterBlock,
    ModalHeaderWindow,
    HallBenefitUnit,
  },
  data() {
    return {
      keyword: null,
      selectedBenefit: null,
    };
  },
  props: {
    mediaId: {
      type: Number,
      default: API_CODE.mediaCommon,
    },
    // 選択中の特典
    benefit: {
      type: Object,
      default: null,
    },
    benefits: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filteredBenefits() {
      const method = filter.state.queries.keyword.method;
      const benefits = this.benefits.filter((benefit) => method(benefit, this.keyword));
      return benefits.sort((prevBenefit, nextBenefit) => {
        const prev = prevBenefit.updated_at;
        const next = nextBenefit.updated_at;
        if (prev === next) {
          return prevBenefit.id > nextBenefit.id ? 1 : -1;
        }
        return prev > next ? -1 : 1;
      });
    },
    selectedBenefitId() {
      const benefit = this.selectedBenefit;
      return benefit ? benefit.id : null;
    },
  },
  created() {
    this.selectedBenefit = deepCopy(this.benefit);
  },
  methods: {
    select(benefit) {
      this.selectedBenefit = deepCopy(benefit);
    },
    filter(_, keyword) {
      this.keyword = keyword;
    },
  },
};
</script>

<style scoped lang="scss">
.hall-benefit-filter {
  margin-bottom: $GRID;
}

.hall-benefit-select {
  @include Box();
  padding: $GRID;

  .benefit-unit {
    width: $GRID * 40;
  }

  .benefit-unit + .benefit-unit {
    margin-top: $GRID * 2;
  }
}
</style>
