import { createSwitchResourceInstance } from "./repository";

export default () => {
  const resource = "/users";
  return {
    index: (params) => createSwitchResourceInstance(resource, "get", { params }),
    store: (params) => createSwitchResourceInstance(resource, "post", params),
    update: (id, params) => createSwitchResourceInstance(`${resource}/${id}`, "put", params),
    destroy: (id) => createSwitchResourceInstance(`${resource}/${id}`, "delete"),
  };
};
