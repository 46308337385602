import { getCookieAccount } from "@/lib/cookie";

import Repository from "./repository";

export default () => {
  const { hall_id, token, userable_id } = getCookieAccount();
  const hallId = hall_id || userable_id;
  const { instance, show } = Repository(`${hallId}`, token);
  return {
    showUsage: () => show("hall/usage"),
    showNotice: () => show("hall/notice"),
    showInSync: () => show("hall/in_sync"),
    readyForImport: () => instance.post("imports/ready"),
  };
};
