import { getCookieAccount } from "@/lib/cookie";

import Repository from "./repository";

export default () => {
  const { hall_id, token, userable_id } = getCookieAccount();
  const hallId = hall_id || userable_id;
  const resource = "fairs";
  const { instance, index, store, show, update, destroy } = Repository(
    `${hallId}/${resource}`,
    token
  );
  return {
    index,
    store,
    show,
    update,
    destroy,
    showTemplate: () => show("template"),
    showRules: () => show("rules"),
    showScheduleCountMonthly: () => show("schedules/count/monthly"),
    showScheduleCountDaily: () => show("schedules/count/daily"),
    showMediaReservation: (fairId, payload) => instance.get(`${fairId}/media-reservation`, payload),
    linkGroup: (fairId, payload) => instance.put(`/${fairId}/group`, payload),
    publish: (fairId) => instance.post(`/${fairId}/publish`),
    unpublish: (fairId) => instance.post(`/${fairId}/unpublish`),
    indexOrder: () => instance.get("order"),
    updateOrder: (payload) => instance.put("order", payload),
    indexMediaOrder: () => instance.get("order/media"),
    updateMediaOrder: (payload) => instance.put("order/media", payload),
    indexSchedules: () => instance.get("schedules"),
    showSchedule: (date, payload) => instance.get(`schedules/${date}`, payload),
    updateSchedule: (fairId, payload) => instance.put(`${fairId}/schedules`, payload),
    showMediaSchedule: (fairId) => instance.get(`${fairId}/schedules/media`),
    showMediaRecommendedState: (payload) => instance.put("media-recommended-state", payload),
    updateRecommended: (fairId, payload) => instance.put(`${fairId}/recommended`, payload),
    showMediaOnlyFairs: () => instance.get("media-recommended-state/media-only-fairs"),
    showBestRecommendedStockNum: () =>
      instance.get("media-recommended-state/best-recommended-stock-num"),
    showReportPlus: () => instance.get("media-recommended-state/report-plus"),
    indexStock: () => instance.get("stock"),
    updateStock: (payload) => instance.put("stock", payload),
    bulkUpdateStock: (payload) => instance.put("stock/bulk", payload),
    destroyDaily: (fairId, payload) => instance.delete(`daily/${fairId}`, payload),
  };
};
