import { getCookieAccount } from "@/lib/cookie";

import { createAxiosInstance } from "./repository";

const authorize = async (payload) => {
  const axios = createAxiosInstance();
  const response = await axios.post("/authenticate", payload);
  return response;
};

const deleteToken = async () => {
  const { token } = getCookieAccount();
  const axios = createAxiosInstance(token);
  const response = await axios.delete("/authenticate");
  return response;
};

const mailAuthenticate = async (payload) => {
  const { token } = getCookieAccount();
  const axios = createAxiosInstance(token);
  const response = await axios.post("/mail-authenticate", payload);
  return response;
};

const indexPermissions = async (payload) => {
  const { token } = getCookieAccount();
  const axios = createAxiosInstance(token);
  const response = await axios.get("/user/permissions", payload);
  return response;
};

const showAuthenticatedUser = async (payload) => {
  const { token } = getCookieAccount();
  const axios = createAxiosInstance(token);
  const response = await axios.get("/user/authenticated", payload);
  return response;
};

export { authorize, deleteToken, mailAuthenticate, indexPermissions, showAuthenticatedUser };
