<template>
  <div class="hall-benefit-setting">
    <action-button
      class="hall-benefit-setting__button"
      text="式場特典の並び替え・公開設定をする"
      @clickHandler="openHallBenefitSetting"
    />
    <benefit-publish-modal
      v-if="showPublishModal !== null"
      :media-id="showPublishModal"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import * as types from "@/store/benefit/mutationTypes";

import router from "@/pages/benefit/router";

import ActionButton from "@/components/atoms/common/ActionButton.vue";
import BenefitPublishModal from "@/components/benefit/hall-benefit-publish/BenefitPublishModal.vue";

export default {
  components: {
    ActionButton,
    BenefitPublishModal,
  },
  computed: {
    ...mapGetters({
      showPublishModal: "showPublishModal",
    }),
  },
  mounted() {
    const route = router.currentRoute.value;
    const hallBenefitId = route.query.hall_benefit_id;
    if (!hallBenefitId) return;

    this.openHallBenefitSetting();
  },
  methods: {
    ...mapMutations({
      updateShowModal: `${types.SHOW_PUBLISH_MODAL}`,
    }),
    openHallBenefitSetting() {
      this.updateShowModal(API_CODE.mediaCommon);
    },
    closeModal() {
      this.updateShowModal(null);
    },
  },
};
</script>

<style scoped lang="scss">
.hall-benefit-setting {
  margin-top: $GRID;

  &__button {
    width: $GRID * 18;
    padding: ($SUB_GRID * 2) $GRID;
    border-radius: $GRID * 2;
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
