import { createStore } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import modal from "@/lib/modal";

import useImageRepository from "@/repositories/image";
import useMediaRepository from "@/repositories/media";
import useTagRepository from "@/repositories/tag";

import filter from "./filter";
import ModalImageSelect from "./ModalImageSelect.vue";

export const WP_WIDTH = 1080;

const imageSelect = {
  namespaced: true,
  modules: {
    filter,
  },
  state: {
    currentValues: {
      selectedImageId: -1,
      enabledMediaIds: [],
      enabledCropType: [],
    },
    images: [],
    master: {
      format: {},
      license_period: 1,
      license_status: {},
      tags: {},
      crop_type: {},
    },
    media: {},
    tags: [],
  },
  getters: {
    selectedImageId: ({ currentValues }) => currentValues.selectedImageId,
    enabledMediaIds: ({ currentValues }) => currentValues.enabledMediaIds,
    enabledCropType: ({ currentValues }) => currentValues.enabledCropType,
    images: ({ images }) => images,
    image: (_, getters) => (imageId) => {
      const image = getters.images.find(({ id }) => id === imageId);
      return image || null;
    },
    master: ({ master }) => master,
    format: ({ master }) => master.format,
    licensePeriod: ({ master }) => master.license_period,
    licenseStatus: ({ master }) => master.license_status,
    tagsMaster: ({ master }) => master.tags,
    cropType: ({ master }) => master.crop_type,
    showModalSelectImage: () => (selectedImageId) => {
      const props = { selectedImageId };
      const { namespaced, ...store } = imageSelect;
      return modal(ModalImageSelect, props, createStore(store));
    },
    media: ({ media }) => media,
    tags: ({ tags }) => tags,
    inoperableCause: (_, getters) => (image, enabledMediaIds, enabledCropType) => {
      // アルバム連携設定のある媒体が含まれる場合、有効になっていれば選択可能に
      if (enabledMediaIds.length > 0) {
        const linkMediaIds = Object.keys(image.is_uploaded).map(Number);
        const unlinked = enabledMediaIds.some((id) => {
          return linkMediaIds.includes(Number(id)) && !image.media.includes(Number(id));
        });
        if (unlinked) {
          return "unlinked";
        }
      }

      // トリミングや画像サイズの判定
      if (enabledCropType.length === 0) {
        return "";
      }
      if (!getters.existsEnabledTrim(image, enabledCropType)) {
        return "untrimmed";
      }
      if (!getters.existsWpWidth(image, enabledCropType)) {
        return "too_short";
      }
      return "";
    },
    existsEnabledTrim: (_, getters) => (image, enabledCropType) => {
      const cropType = Object.values(getters.cropType);
      return cropType.every((type) =>
        enabledCropType.some((enabledType) => enabledType === type) ? !!image.urls[type] : true
      );
    },
    existsWpWidth: () => (image, enabledCropType) =>
      enabledCropType.some((type) => type === WP_WIDTH) ? !!image.urls[String(WP_WIDTH)] : true,
  },
  mutations: {
    currentValues(state, currentValues) {
      state.currentValues = currentValues;
    },
    selectedImageId(state, selectedImageId) {
      state.currentValues = {
        ...state.currentValues,
        selectedImageId,
      };
    },
    images(state, images) {
      state.images = images;
    },
    master(state, master) {
      state.master = master;
    },
    media(state, media) {
      state.media = media;
    },
    tags(state, tags) {
      state.tags = tags;
    },
  },
  actions: {
    showModalSelectImage({ commit }, currentValues) {
      const { namespaced, ...store } = imageSelect;
      commit("currentValues", currentValues);
      return modal(ModalImageSelect, null, createStore(store));
    },
    async fetchImages({ commit }) {
      const { index } = useImageRepository();
      const { data, status } = await index();
      if (status !== API_CODE.response.success) {
        return;
      }
      commit("images", data.data);
      commit("master", data.master);
    },
    async fetchMediaAccounts({ commit }) {
      const { index } = useMediaRepository();
      const { data, status } = await index();
      if (status !== API_CODE.response.success) {
        return;
      }
      commit("media", data.master);
    },
    async fetchTags({ commit }) {
      const { indexImageTags } = useTagRepository();
      const { data, status } = await indexImageTags();
      if (status !== API_CODE.response.success) {
        return;
      }
      commit("tags", data.data);
    },
  },
};

export default imageSelect;
