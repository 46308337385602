<template>
  <benefit-form :note="note" :option-level="optionLevel([keyName])" title="特典ハイライト">
    <label class="checkbox">
      <input-checkbox v-model="value" />
      <span class="checkbox__text">表示する</span>
    </label>
  </benefit-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import * as types from "@/store/benefit/mutationTypes";

import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import InputCheckbox from "@/components/atoms/common/InputCheckbox.vue";

export default {
  components: {
    BenefitForm,
    InputCheckbox,
  },
  data() {
    return {
      keyName: "is_highlighted",
      mediaId: API_CODE.media.mw,
    };
  },
  computed: {
    ...mapGetters({
      optionLevel: "optionLevel",
      needNote: "needNote",
      onlyMediaName: "onlyMediaName",
      isHighlighted: "isHighlighted",
    }),
    note() {
      if (this.needNote(this.keyName)) {
        return this.onlyMediaName(this.keyName);
      }
      return null;
    },
    value: {
      get() {
        return this.isHighlighted(this.mediaId);
      },
      set(highlighted) {
        const mediaId = this.mediaId;
        this.updateHighlight({ mediaId, highlighted });
      },
    },
  },
  methods: {
    ...mapMutations({
      updateHighlight: `${types.IS_HIGHLIGHTED}`,
    }),
  },
};
</script>

<style scoped lang="scss">
.checkbox {
  align-items: center;
  display: flex;

  &__text {
    margin-left: $SUB_GRID;
  }
}
</style>
