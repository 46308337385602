<template>
  <benefit-form title="特典種別" :note="note" :option-level="optionLevel([keyName])">
    <input-radio
      v-model.number="value"
      :items="items"
      :is-required="isRequired(keyName)"
      class="detail-type-list"
      @check="sendValidation"
    />
  </benefit-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import * as types from "@/store/benefit/mutationTypes";

import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import InputRadio from "@/components/benefit/inputs/InputRadio.vue";

export default {
  components: {
    BenefitForm,
    InputRadio,
  },
  data() {
    return {
      keyName: "detail_type",
      mediaId: API_CODE.media.mw,
    };
  },
  computed: {
    ...mapGetters({
      detailType: "detailType",
      detailTypeList: "detailTypeList",
      needNote: "needNote",
      onlyMediaName: "onlyMediaName",
      optionLevel: "optionLevel",
      isRequired: "rules/isRequired",
    }),
    value: {
      get() {
        return this.detailType(this.mediaId);
      },
      set(type) {
        const mediaId = this.mediaId;
        this.updateDetailType({ mediaId, type });
      },
    },
    items() {
      return Object.entries(this.detailTypeList).reduce((items, [key, name]) => {
        const id = Number(key);
        const value = { id, name };
        return { ...items, [id]: value };
      }, {});
    },
    note() {
      if (this.needNote(this.keyName)) {
        return this.onlyMediaName(this.keyName);
      }
      return null;
    },
  },
  beforeUnmount() {
    this.sendValidation(true);
  },
  methods: {
    ...mapMutations({
      updateDetailType: `${types.DETAIL_TYPE}`,
      validateDetailType: `validation/${types.DETAIL_TYPE}`,
    }),
    sendValidation(isValid) {
      this.validateDetailType({ isValid, mediaId: this.mediaId });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-type-list {
  padding: $GRID 0;
  gap: $SUB_GRID ($GRID * 0.5);
}
</style>
