<template>
  <benefit-list-column
    :header-text="headerText"
    :show-hall-benefit-count="enabledHallBenefits"
    :fair-benefit-count="fairBenefitCountZexy"
    :hall-benefit-count="hallBenefitCountZexy"
    :adding="addingFairBenefit || addingHallBenefit"
    :add-title="addTitle"
  >
    <template v-slot:actions>
      <add-button
        text="フェア特典を新規登録する"
        @clickHandler="addFairBenefit"
      />
      <add-button
        v-if="enabledHallBenefits"
        text="式場特典を新規登録する"
        @clickHandler="addHallBenefit"
      />
    </template>
    <template v-slot:list>
      <template v-for="(benefit, key) in zexyBenefits" :key="benefit.id">
        <fair-benefit-unit
          v-if="isFair(benefit.group_type)"
          :data="benefit"
          :is-edit="isEdit(benefit.id, key)"
          :is-loading="loadingNg"
          @updateEditId="setEditingId"
          @showCopyEditModal="copyBenefit(benefit.id)"
          @replace="$emit('replace', benefit.id)"
          ref="unit"
          class="benefit-unit"
        />
        <hall-benefit-unit
          v-else
          :data="benefit"
          :is-edit="isEdit(benefit.id, key)"
          @updateEditId="setEditingId"
          @showCopyEditModal="copyBenefit(benefit.id)"
          class="benefit-unit"
        />
      </template>
    </template>
    <template v-slot:edit>
      <fair-benefit-edit
        v-if="!loadingNg && addingFairBenefit"
        @close="close"
      />
      <hall-benefit-edit
        v-if="!loadingNg && addingHallBenefit"
        @close="close"
      />
    </template>
  </benefit-list-column>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import { mapGetters } from "vuex";

import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";

import benefitListColumn from "@/mixins/benefit-list-column";

import AddButton from "@/components/atoms/common/AddButton.vue";
import FairBenefitEdit from "@/components/benefit/benefit-list/FairBenefitEdit.vue";
import FairBenefitUnit from "@/components/benefit/benefit-list/FairBenefitUnit.vue";
import HallBenefitEdit from "@/components/benefit/benefit-list/HallBenefitEdit.vue";
import HallBenefitUnit from "@/components/benefit/benefit-list/HallBenefitUnit.vue";
import BenefitListColumn from "@/components/benefit/benefit-list/BenefitListColumn.vue";

export default {
  components: {
    AddButton,
    FairBenefitEdit,
    FairBenefitUnit,
    HallBenefitEdit,
    HallBenefitUnit,
    BenefitListColumn,
  },
  mixins: [benefitListColumn],
  props: {
    editingId: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      enabledHallBenefits: "enabledHallBenefits",
      listHeaderText: "listHeaderText",
      zexyBenefits: "filter/zexyBenefits",
      fairBenefitCountZexy: "filter/fairBenefitCountZexy",
      hallBenefitCountZexy: "filter/hallBenefitCountZexy",
    }),
    headerText() {
      return this.listHeaderText(BENEFIT_GROUP_TYPE.fair);
    },
    adding() {
      return this.editing && this.editingId === null;
    },
    addingFairBenefit() {
      if (Object.keys(this.selectedData).length > 0) {
        const { group_type } = this.selectedData;
        const isFair = group_type === BENEFIT_GROUP_TYPE.fair;
        return isFair && this.adding;
      }
      return false;
    },
    addingHallBenefit() {
      if (Object.keys(this.selectedData).length > 0) {
        const { group_type } = this.selectedData;
        const isHall = group_type === BENEFIT_GROUP_TYPE.hall;
        return isHall && this.adding;
      }
      return false;
    },
    addTitle() {
      if (this.addingFairBenefit) {
        return "フェア特典 新規登録";
      }
      if (this.addingHallBenefit) {
        return "式場特典 新規登録";
      }
      return null;
    },
  },
  methods: {
    addBenefit(group_type) {
      // データ初期化（キャンセル押さずに開く可能性があるため）
      this.setSelectedData({ group_type, id: null });

      // 編集モーダル閉じる
      this.setEditingId(null);

      VueScrollTo.scrollTo("#scrollTop");
      this.editing = true;
    },
    addFairBenefit() {
      this.addBenefit(BENEFIT_GROUP_TYPE.fair);
    },
    addHallBenefit() {
      this.addBenefit(BENEFIT_GROUP_TYPE.hall);
    },
    isFair(groupType) {
      return groupType === BENEFIT_GROUP_TYPE.fair;
    },
  },
};
</script>

<style scoped lang="scss">
.benefit-unit {
  margin-top: $GRID * 2;
  transition: all 0.3s;
}
</style>
