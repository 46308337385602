import { getCookieAccount } from "@/lib/cookie";

import Repository from "./repository";

export default () => {
  const { token } = getCookieAccount();
  const resource = "potential-halls";
  const { index } = Repository(`${resource}`, token);
  return { index };
};
