import API_CODE from "@/assets/common/ApiCode";
import { selectHall } from "@/assets/common/PageConfig";

import { getCookieAccount } from "@/lib/cookie";

import useJobRepository from "@/repositories/job";
import { indexDoneLogs } from "@/repositories/log";

import * as commonTypes from "@/store/common/mutationTypes";

const actions = {
  /**
   * 初期設定
   */
  async getInitialData({ commit, getters, dispatch }) {
    await dispatch("common/checkPagePermission");
    await dispatch("setLinkPermission");
    const { userable_type, hall_id } = getCookieAccount();
    const isParentAccount =
      getters["common/isViewableAnyHall"] || getters["common/isViewableOwnHall"];
    commit("SET_IS_SHOW_PARENT", isParentAccount && hall_id === null);
    dispatch("filter/initialize");

    // 初期データの取得
    if (userable_type === "Company") {
      // 企業向けの画面は初期タブを操作履歴に設定
      dispatch("updateTabKey", "operationLogs");
      await dispatch("operationLogs/init");
    } else {
      const resultWait = await dispatch("getWaitingJobsApi");
      const resultExec = await dispatch("getExecutedJobsApi");
      const success = resultExec && resultWait;
      if (!success) {
        dispatch("showErrorModal", "get");
        return;
      }
    }
    commit("common/" + commonTypes.SET_LOADING_FINISH);

    // 待機中ジョブの取得・表示
    if (userable_type === "Hall" || hall_id !== null) {
      dispatch("utility/jobs/getJobsData");
    }
  },
  /**
   * 実行済みの処理リストを取得するAPIを実行
   */
  async getExecutedJobsApi({ commit, dispatch, state }) {
    if (state.filter.filterType == "executed") {
      commit("modal/setUpdateModal", { isShow: true, text: "検索中です…" });
    }
    // 検索項目からクエリパラメータ作成
    let params = {
      [`${state.queryName["period"]}_from`]: state.filter.filter.period.from,
      [`${state.queryName["period"]}_to`]: state.filter.filter.period.to,
    };
    Object.keys(state.filter.filter).forEach((k) => {
      if (k != "period" && state.filter.filter[k]) {
        params[state.queryName[k]] = state.filter.filter[k];
      }
    });

    const { data, status } = await indexDoneLogs(params);
    if (status !== API_CODE.response.success) {
      commit("modal/setUpdateModal", { isShow: false });
      return false;
    }

    commit("UPDATE_MASTER", data.master);
    commit("UPDATE_EXECUTED_JOBS", await dispatch("setAdditionalData", data.data));
    commit("modal/setUpdateModal", { isShow: false });
    return true;
  },
  /**
   * 待機中の処理リストを取得するAPIを実行
   */
  async getWaitingJobsApi({ commit }) {
    const params = { contains_in_sync: false };
    const { indexIncompletedJobs } = useJobRepository();
    const { data, status } = await indexIncompletedJobs(params);
    if (status !== API_CODE.response.success) {
      return false;
    }

    commit("UPDATE_MASTER", data.master);
    commit("UPDATE_WAITING_JOBS", data.data);
    return true;
  },
  /**
   * 初期データに追加
   * @param {Object} data 初期データ
   */
  async setAdditionalData({ state }, data) {
    return data.map((d) => {
      // isDisabledLink 追加
      let type = d.queueable_type.toLowerCase();
      const authorizedKey =
        type == "venue"
          ? "setting"
          : Object.keys(state.isAuthorizedLink).find((k) => type.indexOf(k) !== -1);
      if (authorizedKey) {
        d.isDisabledLink = !state.isAuthorizedLink[authorizedKey].edit;
      }
      return d;
    });
  },
  // タブ切り替え
  updateTabKey({ commit }, key) {
    commit("SET_TAB_KEY", key);
  },
  /**
   * エラーモーダルのメッセージを変更する
   * @param {*} param0
   * @param {String} text メッセージ
   */
  setErrorText({ commit }, text) {
    commit("UPDATE_ERROR_TEXT", text);
  },
  /**
   * エラーモーダルを表示する
   * @param {*} param0
   * @param {*} type セット済みのメッセージが無い場合に type 別デフォルトメッセージを表示
   */
  showErrorModal({ commit, state }, type) {
    commit("common/" + commonTypes.ERROR);
    const errorModal = {
      isShow: true,
      text: state.errorText || state.setup.errorTexts[type],
    };
    commit("modal/setErrorModal", errorModal);
  },
  /**
   * ページ移動
   * @param {Object} payload { path, hallId }
   */
  movePage({}, payload) {
    const { path, hallId } = payload;
    if (hallId) selectHall(hallId);
    window.location.href = path;
  },
  // 各 link の表示権限
  async setLinkPermission({ commit, state, getters }) {
    Object.keys(state.isAuthorizedLink).forEach((unit) => {
      let value = {};
      Object.keys(state.isAuthorizedLink[unit]).forEach((k) => {
        if (k == "permissionName") return;
        value[k] = getters["common/isAuthorized"]({
          name: state.isAuthorizedLink[unit].permissionName,
          type: k,
        });
      });
      commit("SET_IS_AUTHORIZED_LINK", { name: unit, value });
    });
  },
};
export default actions;
