import { getCookieAccount } from "@/lib/cookie";

import Repository from "./repository";

export default () => {
  const { hall_id, token, userable_id } = getCookieAccount();
  const hallId = hall_id || userable_id;
  const resource = "hp-settings";
  const { instance, show } = Repository(`${hallId}/${resource}`, token);
  return {
    showDataSetting: () => show("data"),
    patchDataSetting: (payload) => instance.patch("data", payload),
    showContactSetting: () => show("contact"),
    updateContactSetting: (payload) => instance.put("contact/bulk", payload),
    resend: (id) => instance.post(`contact/${id}/resend`),
    showFormSetting: () => show("form"),
    showFormSettingRules: () => show("form/rules"),
    updateFormSetting: (payload) => instance.put("form", payload),
    showDesignSetting: () => show("design"),
    showDesignSettingRules: () => show("design/rules"),
    updateDesignSetting: (payload) => instance.put("design", payload),
    showMetaSetting: () => show("meta"),
    showMetaSettingRules: () => show("meta/rules"),
    updateMetaSetting: (payload) => instance.put("meta", payload),
    showPageSetting: () => show("page"),
    showPageSettingRules: () => show("page/rules"),
    updatePageSetting: (payload) => instance.put("page", payload),
    showPreviewUrl: () => show("preview-url"),
  };
};
