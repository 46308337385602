<template>
  <section class="publish-unit">
    <div class="publish-unit-id">#{{ benefitId }}</div>
    <div class="publish-unit-index">{{ index }}</div>
    <section class="publish-unit-box" :class="{ published }">
      <p class="publish-unit-box__type">{{ benefitType }}</p>
      <benefit-img :path="imageUrl" height="64px" width="64px" class="publish-unit-box__image" />
      <p v-if="isRecommended" class="publish-unit-box__recommend">ウエパおすすめ</p>
      <div class="publish-unit-box__detail">
        <div class="detail__period item">
          <p class="item__label">適用期間</p>
          <p class="item__value" :class="customClass">
            {{ validPeriod }}
          </p>
        </div>
        <div class="detail__title item">
          <p class="item__label">特典名</p>
          <p class="item__value" :class="{ 'wide-label': wideLabel }">
            {{ title }}
          </p>
        </div>
        <div class="detail__description item">
          <p class="item__label">備考</p>
          <p class="item__value" :class="{ 'wide-label': wideLabel }">
            {{ description }}
          </p>
        </div>
      </div>
      <action-button
        class="publish-unit-box__toggle-button"
        :class="{ published }"
        :text="buttonText"
        @clickHandler="$emit('click')"
      />
      <action-button
        v-if="published"
        class="publish-unit-box__button-top"
        text="一番上へ"
        :is-small="true"
        :is-select="true"
        :disabled="isFirst"
        @clickHandler="$emit('moveToUp')"
      />
    </section>
  </section>
</template>

<script>
import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";

import BenefitImg from "@/components/atoms/common/FairImg.vue";
import ActionButton from "@/components/atoms/common/ActionButton.vue";

import formatDateText from "@/lib/formatDateText";

import { mapGetters } from "vuex";

const getValue = (item, mediaId) => item[mediaId] || item[API_CODE.mediaCommon];

export default {
  emits: ["click", "moveToUp"],
  components: {
    BenefitImg,
    ActionButton,
  },
  props: {
    benefitId: {
      type: Number,
      required: true,
    },
    mediaId: {
      type: Number,
      default: API_CODE.media.wp,
    },
    type: {
      type: Object,
      default: null,
    },
    recommended: {
      type: Object,
      default: null,
    },
    imageId: {
      type: [Array, Object],
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    titles: {
      type: Object,
      default: null,
    },
    descriptions: {
      type: Object,
      default: null,
    },
    validFroms: {
      type: Object,
      default: null,
    },
    validUntils: {
      type: Object,
      default: null,
    },
    licenseStatuses: {
      type: Object,
      default: null,
    },
    published: {
      type: Boolean,
      default: false,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    wideLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      image: "image",
      $benefitTypes: "benefitTypeOptions",
      master: "master",
    }),
    groupTypeWp() {
      return BENEFIT_GROUP_TYPE.wp;
    },
    imageUrl() {
      const imageId = getValue(this.imageId, this.mediaId);
      if (imageId === null) return null;

      const image = this.image(imageId);
      return image.urls ? image.urls.thumb : null;
    },
    benefitType() {
      const benefitTypes = this.$benefitTypes(this.groupTypeWp);
      const targetType = Object.values(this.type).find((type) => type);
      return (benefitTypes[targetType] || {}).name;
    },
    isRecommended() {
      return this.recommended[this.mediaId];
    },
    title() {
      return this.titles ? getValue(this.titles, this.mediaId) : null;
    },
    description() {
      const descriptions = this.descriptions;
      return descriptions ? getValue(descriptions, this.mediaId) : null;
    },
    status() {
      const statuses = this.licenseStatuses;
      return statuses ? getValue(statuses, this.mediaId) : null;
    },
    validPeriod() {
      // 開始日と終了日をそれぞれフォーマットした後に取得する
      const froms = this.validFroms;
      const from = froms ? getValue(froms, this.mediaId) : null;
      const fromText = from ? formatDateText(from) : "";
      const untils = this.validUntils;
      const until = untils ? getValue(untils, this.mediaId) : null;
      const untilText = until ? formatDateText(until) : "";
      const periodText = `${fromText} ～ ${untilText}`;
      const status = this.status;
      if (!status) return periodText;

      // 終了・終了間近の場合はステータスも取得する
      const statusMaster = this.master.license_status;
      const statusText = status ? `${statusMaster[status]}: ` : "";
      return `${statusText}${periodText}`;
    },
    customClass() {
      const status = this.status;
      return { soon: status === 1, expired: status === 2 };
    },
    buttonText() {
      return this.published ? "非公開にする" : "公開する";
    },
  },
};
</script>

<style lang="scss" scoped>
.publish-unit {
  &-id {
    grid-column: 2;
    grid-row: 1;
    font-size: 14px;
  }

  &-index {
    grid-column: 1;
    grid-row: 2;
    font-size: 14px;
  }

  &-box {
    @include Box();
    grid-column: 2;
    grid-row: 2;
    padding: ($GRID * 0.5) $GRID;
    gap: $GRID * 0.5;
    font-size: $FONT_SIZE_S;
    background-color: #eee;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-template-rows: max-content max-content;

    &__type {
      grid-column: 1;
      grid-row: 1;
      color: $COLOR_KEY;
      font-size: 11px;
      text-decoration: underline;
    }

    &__image {
      grid-column: 1;
      grid-row: 2;
    }

    &__recommend {
      grid-column: 2;
      grid-row: 1;
      color: $COLOR_KEY;
      font-size: 10px;
      border: solid 1px;
      border-radius: $GRID * 0.5;
      padding: 0 ($GRID * 0.5);
      font-weight: bolder;
      margin-right: auto;
    }

    &__detail {
      grid-column: 2;
      grid-row: 2;
    }

    &__toggle-button {
      align-self: center;
      grid-column: 3;
      grid-row: span 2;

      &.published {
        color: $COLOR_KEY;
        background-color: $BG_COLOR_BOX;
        border: 1px solid $COLOR_KEY;
      }
    }

    &__button-top {
      align-self: center;
      grid-column: 4;
      grid-row: span 2;
      background: #fff;
    }

    &.published {
      background-color: $BG_COLOR_BOX;
    }
  }
}

.detail {
  &__title {
    color: $COLOR_KEY;
    font-weight: bold;
  }
}

.item {
  display: flex;
  align-items: center;

  &__label {
    width: $GRID * 5;
  }

  &__value {
    width: $GRID * 28;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.wide-label {
      width: $GRID * 32.5;
    }
  }
}

.item + .item {
  margin-top: $SUB_GRID;
}

.soon,
.expired {
  width: auto;
  color: #fff;
  border-radius: $GRID * 0.5;
  padding: 0 ($GRID * 0.5);
}

.soon {
  background: $BG_PERIOD_WARNING;
}

.expired {
  background: $BG_PERIOD_ALERT;
}
</style>
