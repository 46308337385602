<template>
  <section class="benefit-detail" :class="detailClass">
    <template v-if="benefitId">
      <div class="benefit-detail__info item">
        <p class="benefit-detail__status">{{ status }}</p>
        <p class="benefit-detail__id">ID: {{ benefitId }}</p>
      </div>
      <div class="benefit-detail__type item">
        {{ typeLabel }}
      </div>
      <div class="benefit-detail__title item">
        <p class="item__label">特典名</p>
        <p class="item__value">{{ title }}</p>
      </div>
      <div class="benefit-detail__notes item">
        <p class="item__label">備考</p>
        <p class="item__value">{{ notes }}</p>
      </div>
      <div class="benefit-detail__period item">
        <p class="item__label">掲載期間</p>
        <p class="item__value">
          <span v-if="published">{{ validFrom }}</span>
          <input-datepicker
            v-else
            v-model="validFrom"
            :date="validFrom"
            :has-warning="hasFromWarning"
            :is-required="true"
            :disabled-dates="disabledFrom(mediaId)"
            key-name="validFrom"
          />
          <span class="">～</span>
          <input-datepicker
            v-model="validUntil"
            :date="validUntil"
            :has-warning="hasUntilWarning"
            :has-error="hasUntilError"
            :is-required="isValidUntilRequired"
            :disabled-dates="disabledUntil(mediaId)"
            key-name="validUntil"
          />
        </p>
      </div>
      <p v-if="hasFromWarning" class="benefit-detail__alert warning">
        <span> 掲載中の特典の掲載期間が終了するまで、予約中の特典は掲載されません。 </span>
      </p>
      <action-button
        class="benefit-detail__change-button"
        text="特典を変更する"
        @clickHandler="$emit('change')"
      />
      <circle-icon-button
        icon="close"
        class="benefit-detail__clear-button"
        @click="$emit('clear')"
      />
    </template>
    <action-button
      v-else
      class="benefit-detail__add-button"
      :text="addBenefitLabel"
      :is-large="true"
      @clickHandler="$emit('change')"
    />
  </section>
</template>

<script>
import { isBefore, parseISO, startOfToday } from "date-fns";

import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_TYPE from "@/assets/common/benefit-type";

import { disabledDateFrom, disabledDateUntil } from "@/lib/date";

import ActionButton from "@/components/atoms/common/ActionButton.vue";
import CircleIconButton from "@/components/atoms/common/CircleIconButton.vue";
import InputDatepicker from "@/components/benefit/inputs/InputDatepicker.vue";

export default {
  components: {
    ActionButton,
    CircleIconButton,
    InputDatepicker,
  },
  props: {
    benefitId: {
      type: Number,
      default: null,
    },
    mediaId: {
      type: Number,
      default: API_CODE.media.zexy,
    },
    modelValue: {
      type: Object,
      default: () => ({
        from: null,
        until: null,
      }),
    },
    type: {
      type: Object,
      default: null,
    },
    titles: {
      type: Object,
      default: null,
    },
    notesList: {
      type: Object,
      default: null,
    },
    published: {
      type: Boolean,
      default: false,
    },
    hasFromWarning: {
      type: Boolean,
      default: false,
    },
    hasUntilWarning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    validFrom: {
      get() {
        return this.modelValue.from;
      },
      set(from) {
        this.$emit("update:modelValue", {
          ...this.modelValue,
          from,
        });
      },
    },
    validUntil: {
      get() {
        return this.modelValue.until;
      },
      set(until) {
        this.$emit("update:modelValue", {
          ...this.modelValue,
          until,
        });
      },
    },
    status() {
      return this.published ? "掲載中" : "予約中";
    },
    addBenefitLabel() {
      const status = this.published ? "掲載" : "予約";
      return `${status}する特典を選択する`;
    },
    typeLabel() {
      if (this.type) {
        const type = this.type[API_CODE.mediaCommon];
        return type === BENEFIT_TYPE.visit ? "来館特典" : "成約特典";
      }
      return null;
    },
    title() {
      if (this.titles) {
        return this.titles[this.mediaId] || this.titles[API_CODE.mediaCommon];
      }
      return null;
    },
    notes() {
      if (this.notesList) {
        return this.notesList[this.mediaId] || this.notesList[API_CODE.mediaCommon];
      }
      return null;
    },
    detailClass() {
      return {
        published: this.published,
        empty: this.benefitId === null,
      };
    },
    isValidUntilRequired() {
      return this.mediaId === API_CODE.media.zexy;
    },
    hasUntilError() {
      const until = parseISO(this.validUntil);
      return isBefore(until, startOfToday());
    },
  },
  methods: {
    disabledFrom() {
      const until = this.validUntil;
      return disabledDateFrom(until);
    },
    disabledUntil() {
      const from = this.validFrom;
      return disabledDateUntil(from);
    },
  },
};
</script>

<style lang="scss" scoped>
.benefit-detail {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 0 $GRID ($GRID * 0.5);
  gap: $GRID * 0.5;
  background-color: $BG_COLOR_FLOOR;

  &.published {
    background-color: $BG_COLOR_BOX;
  }

  &.empty {
    align-items: center;
    justify-content: center;
  }

  .item {
    display: flex;
    width: 100%;
    gap: $GRID;
    align-items: center;

    &__label {
      width: $GRID * 3;
      font-size: $FONT_SIZE_S;
    }

    &__value {
      flex: 1;
      font-size: $FONT_SIZE_S;
    }
  }

  &__status {
    padding: $SUB_GRID $GRID;
    background-color: $COLOR_WARNING;
    color: #fff;
    font-weight: bold;

    .published & {
      background-color: $COLOR_KEY;
    }
  }

  &__type {
    color: $COLOR_KEY;
    font-size: 11px;
    text-decoration: underline;
  }

  &__title {
    color: $COLOR_KEY;
    font-weight: bold;
  }

  &__period {
    .item__value {
      display: flex;
      align-items: center;
      gap: $SUB_GRID;
    }
  }

  .warning {
    display: flex;
    width: 100%;
    font-size: $FONT_SIZE_SS;
    color: $COLOR_ALERT;
    font-weight: bold;

    &:before {
      content: "※";
      margin-right: $SUB_GRID;
    }
  }

  &__change-button {
    width: max-content;
    padding: $SUB_GRID ($GRID * 1.5);
    align-self: flex-end;
  }

  &__clear-button {
    position: absolute;
    top: $SUB_GRID;
    right: $SUB_GRID;
  }
}
</style>
