<template>
  <div>
    <modal-loading v-if="loading" />
    <div v-else>
      <page-title icon="stars" title="特典管理" />
      <benefit-filter />
      <hall-benefit-setting v-if="enabledHallBenefits" />
      <benefit-list />
    </div>
    <modal-state />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import PageTitle from "@/components/atoms/common/PageTitle.vue";
import BenefitFilter from "@/components/benefit/benefit-list/BenefitFilter.vue";
import HallBenefitSetting from "@/components/benefit/benefit-list/HallBenefitSetting.vue";
import BenefitList from "@/components/benefit/benefit-list/BenefitList.vue";
import ModalState from "@/components/organisms/common/ModalState.vue";
import ModalLoading from "@/components/molecules/common/ModalLoading.vue";

export default {
  components: {
    PageTitle,
    BenefitFilter,
    HallBenefitSetting,
    BenefitList,
    ModalState,
    ModalLoading,
  },
  computed: {
    ...mapGetters({
      enabledHallBenefits: "enabledHallBenefits",
    }),
    ...mapState("common", { loading: (state) => state.loading }),
    ...mapState(["targetId"]),
  },
  watch: {
    targetId(val) {
      // 編集中の場合、状態確認のタイマーを一時中止
      this.statusCheckerPause({ isPause: !!val });
    },
  },
  methods: {
    ...mapActions({
      init: "getInitialData",
      statusCheckerInit: "statusChecker/init",
      statusCheckerReset: "statusChecker/reset",
      statusCheckerPause: "statusChecker/pauseOrRestartTimer",
    }),
  },
  mounted() {
    this.init().then(() => this.statusCheckerInit({ target: "benefit" }));
  },
  beforeUnmount() {
    this.statusCheckerReset();
  },
};
</script>
