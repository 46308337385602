<template>
  <modal-header-window
    icon="campaign"
    title="式場特典の公開"
    :is-set-footer="false"
    class="publish-modal"
    @close="close"
  >
    <template v-slot:content>
      <tabs-container
        v-model="currentTab"
        :entries="entries"
        :displayed-ids="displayIds"
        :has-bg-color="true"
      >
        <template v-for="id in displayIds" v-slot:[id] :key="id">
          <component :is="currentComponent(id)" />
        </template>
      </tabs-container>
    </template>
  </modal-header-window>
</template>

<script>
import { mapGetters } from "vuex";

import API_CODE from "@/assets/common/ApiCode";
import HALL_BENEFIT_TYPE from "@/assets/benefit/hall-benefit-type";

import modal from "@/lib/modal";

import router from "@/pages/benefit/router";

import ModalHeaderWindow from "@/components/molecules/common/ModalHeaderWindow.vue";
import ModalConfirm from "@/components/modals/ModalConfirm.vue";
import TabsContainer from "@/components/plan-detail/containers/TabsContainer.vue";
import ContractBenefitMw from "@/components/benefit/hall-benefit-publish/mw-setting/ContractBenefitMw.vue";
import VisitBenefitMw from "@/components/benefit/hall-benefit-publish/mw-setting/VisitBenefitMw.vue";
import BenefitMynavi from "@/components/benefit/hall-benefit-publish/mynavi-setting/BenefitMynavi.vue";
import BenefitWp from "@/components/benefit/hall-benefit-publish/wp-setting/BenefitWp.vue";
import BenefitZexy from "@/components/benefit/hall-benefit-publish/zexy-setting/BenefitZexy.vue";

const TAB_ENTRIES = {
  zexy: 0,
  mwVisit: 1,
  mwContract: 2,
  mynavi: 3,
  wp: 4,
};

const TAB_INFO = {
  [TAB_ENTRIES.zexy]: {
    mediaId: API_CODE.media.zexy,
    typeList: [HALL_BENEFIT_TYPE.visit, HALL_BENEFIT_TYPE.contract],
  },
  [TAB_ENTRIES.mwVisit]: {
    mediaId: API_CODE.media.mw,
    typeList: [HALL_BENEFIT_TYPE.visit],
  },
  [TAB_ENTRIES.mwContract]: {
    mediaId: API_CODE.media.mw,
    typeList: [HALL_BENEFIT_TYPE.contract],
  },
  [TAB_ENTRIES.mynavi]: {
    mediaId: API_CODE.media.mynavi,
    typeList: [HALL_BENEFIT_TYPE.common],
  },
  [TAB_ENTRIES.wp]: {
    mediaId: API_CODE.media.wp,
    typeList: [HALL_BENEFIT_TYPE.common],
  },
};

export default {
  components: {
    ModalHeaderWindow,
    ModalConfirm,
    TabsContainer,
    ContractBenefitMw,
    VisitBenefitMw,
    BenefitMynavi,
    BenefitWp,
    BenefitZexy,
  },
  props: {
    mediaId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      entries: Object.values(TAB_ENTRIES).map((id) => {
        const tabNames = {
          [TAB_ENTRIES.zexy]: "ゼクシィ",
          [TAB_ENTRIES.mwVisit]: "みんな(来館)",
          [TAB_ENTRIES.mwContract]: "みんな(成約)",
          [TAB_ENTRIES.mynavi]: "マイナビ",
          [TAB_ENTRIES.wp]: "ウエパ",
        };
        return { id, short_name: tabNames[id] };
      }),
      displayIds: Object.values(TAB_ENTRIES),
      selectedTab: Object.values(TAB_ENTRIES)[0],
    };
  },
  computed: {
    ...mapGetters({
      hasChanged: "publishing/hasChanged",
      benefitTypeByHallBenefit: "publishing/benefitTypeByHallBenefit",
    }),
    currentTab: {
      get() {
        return this.selectedTab;
      },
      set(value) {
        const props = {
          text: `タブを変更しますか？
保存していない変更は削除されます`,
          buttonText: "変更する",
          closeText: "キャンセル",
        };
        const callback = () => {
          this.selectedTab = value;
        };
        this.showConfirmModal(props, callback);
      },
    },
  },
  mounted() {
    const route = router.currentRoute.value;
    const hallBenefitId = Number(route.query.hall_benefit_id);
    const mediaId = Number(route.query.media_id);

    if (!hallBenefitId || this.mediaId === API_CODE.media.wp) {
      this.selectedTab = this.mediaId;
      return;
    }

    const type = this.benefitTypeByHallBenefit(mediaId, hallBenefitId);
    const tabEntry = Object.keys(TAB_INFO).find((tabEntry) => {
      const { mediaId: tabMediaId, typeList } = TAB_INFO[tabEntry];
      return tabMediaId === mediaId && typeList.includes(type);
    });
    this.selectedTab = tabEntry ? Number(tabEntry) : this.mediaId;
  },
  methods: {
    currentComponent(entry) {
      const components = {
        [TAB_ENTRIES.zexy]: BenefitZexy,
        [TAB_ENTRIES.mwVisit]: VisitBenefitMw,
        [TAB_ENTRIES.mwContract]: ContractBenefitMw,
        [TAB_ENTRIES.mynavi]: BenefitMynavi,
        [TAB_ENTRIES.wp]: BenefitWp,
      };
      return components[entry];
    },
    close() {
      const props = {
        text: `並び替え・公開設定を終了しますか？\r\n保存していない変更は削除されます`,
        buttonText: "閉じる",
        closeText: "キャンセル",
      };
      const callback = () => {
        this.$emit("closeModal");
      };
      this.showConfirmModal(props, callback);
    },
    showConfirmModal(props, callback) {
      const { mediaId, typeList } = TAB_INFO[this.selectedTab];
      const hasChanged = typeList.some((benefitType) => this.hasChanged(mediaId, benefitType));

      if (hasChanged) {
        const confirmModal = modal(ModalConfirm, props);
        confirmModal.then(() => {
          callback();
        });
      } else {
        callback();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.publish-modal {
  ::v-deep .modalHeaderWindow {
    height: calc(100vh - #{$GRID} * 2);

    .modalContent {
      height: 100%;
    }

    .modalContentInr {
      height: 100%;
      width: $GRID * 60;
    }
  }
  ::v-deep .tabs {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__contents {
      height: 100%;
      overflow: hidden;
      padding: 0;
    }

    &__content {
      &--current {
        height: 100%;
        overflow: hidden;
      }
    }
  }
}
</style>
