<template>
  <div class="list-head">
    <div class="list-head__count">
      <p class="fair-benefit-count">
        <span class="benefit-type">フェア特典：</span>
        <span class="fontNum-b">{{ fairBenefitCount }}</span>
        <span>種類</span>
      </p>
      <p v-if="showHallBenefitCount" class="hall-benefit-count">
        <span class="benefit-type">式場特典：</span>
        <span class="fontNum-b">{{ hallBenefitCount }}</span>
        <span>種類</span>
      </p>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    showHallBenefitCount: {
      type: Boolean,
      default: false,
    },
    fairBenefitCount: {
      type: Number,
      required: true,
    },
    hallBenefitCount: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.list-head {
  @include gradientMain();
  box-shadow: $DEPTH_LV_2;
  padding: 25px 0 $GRID;
  text-align: center;

  &__count {
    display: flex;
    justify-content: center;
    color: $COLOR_KEY;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 27px;
  }

  .hall-benefit-count {
    margin-left: $GRID;
  }

  .benefit-type {
    font-size: 18px;
  }

  .fontNum-b {
    margin: 0 2px;
    font-size: 36px;
  }
}
</style>
