<template>
  <benefit-list-column
    :header-text="headerText"
    :show-hall-benefit-count="enabledHallBenefits"
    :fair-benefit-count="fairBenefitCountWp"
    :hall-benefit-count="hallBenefitCountWp"
    :adding="adding"
  >
    <template v-slot:actions>
      <add-button text="特典を新規登録する" @clickHandler="addBenefit()" />
    </template>
    <template v-slot:list>
      <fair-benefit-unit
        v-for="(benefit, key) in wpBenefits"
        :key="benefit.id"
        :data="benefit"
        :is-edit="isEdit(benefit.id, key)"
        :is-loading="loadingNg"
        @updateEditId="setEditingId"
        @showCopyEditModal="copyBenefit(benefit.id)"
        @replace="$emit('replace', benefit.id)"
        ref="unit"
        class="benefit-unit"
      />
    </template>
    <template v-slot:edit>
      <fair-benefit-edit v-if="!loadingNg" @close="close" />
    </template>
  </benefit-list-column>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import { mapGetters } from "vuex";

import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";

import benefitListColumn from "@/mixins/benefit-list-column";

import AddButton from "@/components/atoms/common/AddButton.vue";
import FairBenefitEdit from "@/components/benefit/benefit-list/FairBenefitEdit.vue";
import FairBenefitUnit from "@/components/benefit/benefit-list/FairBenefitUnit.vue";
import BenefitListColumn from "@/components/benefit/benefit-list/BenefitListColumn.vue";

export default {
  components: {
    AddButton,
    FairBenefitEdit,
    FairBenefitUnit,
    BenefitListColumn,
  },
  mixins: [benefitListColumn],
  props: {
    editingId: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      enabledHallBenefits: "enabledHallBenefits",
      listHeaderText: "listHeaderText",
      wpBenefits: "filter/wpBenefits",
      fairBenefitCountWp: "filter/fairBenefitCountWp",
      hallBenefitCountWp: "filter/hallBenefitCountWp",
    }),
    headerText() {
      return this.listHeaderText(BENEFIT_GROUP_TYPE.wp);
    },
    adding() {
      if (Object.keys(this.selectedData).length > 0) {
        const { group_type } = this.selectedData;
        const isWp = group_type === BENEFIT_GROUP_TYPE.wp;
        return isWp && this.editing && this.editingId === null;
      }
      return false;
    },
  },
  methods: {
    addBenefit() {
      // データ初期化（キャンセル押さずに開く可能性があるため）
      const group_type = BENEFIT_GROUP_TYPE.wp;
      this.setSelectedData({ group_type, id: null });

      // 編集モーダル閉じる
      this.setEditingId(null);

      VueScrollTo.scrollTo("#scrollTop");
      this.editing = true;
    },
  },
};
</script>

<style scoped lang="scss">
.benefit-unit {
  margin-top: $GRID * 2;
}
</style>
