<template>
  <container-benefit-publish
    :has-changed="hasChanged"
    :is-all-valid="isAllValid"
    @submit="putHallBenefitsZexy"
  >
    <draggable-template-list
      value-label="来館特典"
      :media-id="mediaId"
      :benefit-type="benefitType.visit"
      class="draggable-list"
    />
    <draggable-template-list
      value-label="成約特典"
      :media-id="mediaId"
      :benefit-type="benefitType.contract"
      class="draggable-list"
    />
  </container-benefit-publish>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import API_CODE from "@/assets/common/ApiCode";
import HALL_BENEFIT_TYPE from "@/assets/benefit/hall-benefit-type";

import ContainerBenefitPublish from "@/components/benefit/hall-benefit-publish/ContainerBenefitPublish.vue";
import DraggableTemplateList from "@/components/benefit/hall-benefit-publish/DraggableTemplateList.vue";

export default {
  components: {
    ContainerBenefitPublish,
    DraggableTemplateList,
  },
  data() {
    return {
      mediaId: API_CODE.media.zexy,
      benefitType: HALL_BENEFIT_TYPE,
      initialized: false,
    };
  },
  computed: {
    ...mapGetters({
      templateList: "publishing/templateListByBenefitType",
      $hasChanged: "publishing/hasChanged",
      isAllValid: "publishing/isAllValid",
    }),
    hasChanged() {
      if (!this.initialized) {
        return false;
      }

      const mediaId = this.mediaId;
      return Object.values(this.benefitType).some((benefitType) =>
        this.$hasChanged(mediaId, benefitType)
      );
    },
  },
  mounted() {
    this.initialized = true;
  },
  methods: {
    ...mapActions({
      putHallBenefitsZexy: "publishing/putHallBenefitsZexy",
    }),
  },
};
</script>

<style lang="scss" scoped>
.draggable-list + .draggable-list {
  margin-top: $GRID * 2;
}
</style>
