<template>
  <benefit-form :title="title" :note="note" :option-level="0">
    <by-media-input
      :title="title"
      :is-by-media="isByMedia"
      :is-disabled="isDisabled"
      @toggleByMedia="isByMedia = !isByMedia"
    >
      <input-item
        v-for="mediaId in targetMedia"
        :media-name="mediaShortName(mediaId)"
        :is-by-media="mediaId !== commonId"
        :key="mediaId"
      >
        <div class="publication">
          <p class="publication-item">
            <input-option-icon
              :option-level="optionLevel([keyNameFrom], mediaId)"
              size="s"
              class="publication-item__icon"
            />
            <span class="publication-item__title">掲載開始</span>
            <input-datepicker
              :model-value="publicationFrom(mediaId)"
              :is-required="isRequired(keyNameFrom, mediaId)"
              :is-disabled="isPublished(mediaId)"
              :disabled-dates="disabledFrom(mediaId)"
              @update:modelValue="setPublicationDateFrom(mediaId, $event)"
              @check="validateFrom(mediaId, $event)"
            />
          </p>
          <p class="publication-item">
            <input-option-icon
              :option-level="optionLevelUntil(mediaId)"
              size="s"
              class="publication-item__icon"
            />
            <span class="publication-item__title">掲載終了</span>
            <input-datepicker
              :model-value="publicationUntil(mediaId)"
              :is-required="requiredUntil(mediaId)"
              :disabled-dates="disabledUntil(mediaId)"
              :has-error="hasErrorUntil(mediaId)"
              @update:modelValue="setPublicationDateUntil(mediaId, $event)"
              @check="validateUntil(mediaId, $event)"
            />
          </p>
        </div>
      </input-item>
    </by-media-input>
  </benefit-form>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { isBefore, parseISO } from "date-fns";

import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_TYPE from "@/assets/common/benefit-type";

import { disabledDateFrom, disabledDateUntil } from "@/lib/date";

import * as types from "@/store/benefit/mutationTypes";

import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import ByMediaInput from "@/components/benefit/presentation/ByMediaInput.vue";
import InputOptionIcon from "@/components/atoms/common/InputOptionIcon.vue";
import InputDatepicker from "@/components/benefit/inputs/InputDatepicker.vue";
import InputItem from "@/components/benefit/inputs/InputItem.vue";

export default {
  components: {
    BenefitForm,
    ByMediaInput,
    InputOptionIcon,
    InputDatepicker,
    InputItem,
  },
  data() {
    return {
      commonId: API_CODE.mediaCommon,
      title: "掲載予定期間",
      keyNameFrom: "publication_from",
      KeyNameUntil: "publication_until",
    };
  },
  computed: {
    ...mapGetters({
      publicationFrom: "publicationFrom",
      publicationUntil: "publicationUntil",
      applicationUntil: "applicationUntil",
      benefitType: "benefitType",
      isByMedium: "isByMedium",
      mediaIds: "mediaIds",
      mediaShortName: "mediaShortName",
      needNote: "needNote",
      optionLevel: "optionLevel",
      onlyMediaName: "onlyMediaName",
      selectedOnlyMedia: "selectedOnlyMedia",
      targetId: "targetId",
      validUntil: "periodUntil",
      $isPublished: "isPublished",
      isRequired: "rules/isRequired",
      $onlyMedia: "rules/onlyMedia",
    }),
    isByMedia: {
      get() {
        return this.isByMedium(this.keyNameFrom);
      },
      set(value) {
        this.setIsByMedia(value);
        const targetMedia = value ? [API_CODE.mediaCommon] : this.onlyMedia;
        this.resetMediaValidation(targetMedia);
      },
    },
    onlyMedia() {
      return this.$onlyMedia(this.keyNameFrom);
    },
    targetMedia() {
      const selectedOnlyMedia = this.selectedOnlyMedia(this.keyNameFrom);
      return this.isByMedia ? selectedOnlyMedia : [API_CODE.mediaCommon];
    },
    note() {
      if (this.needNote(this.keyNameFrom)) {
        return this.onlyMediaName(this.keyNameFrom);
      }
      return null;
    },
    isDisabled() {
      const mediaId = API_CODE.mediaCommon;
      const isPublished = this.isPublished(mediaId);
      // 共通設定の時に 共通 <=> 媒体個別を自由に切り替えられるように実際に保存されている値で媒体個別の判定を実施する
      const isByMedia = this.publicationFrom(mediaId) === null;
      return isPublished && isByMedia;
    },
  },
  beforeUnmount() {
    const targetMedia = [...this.onlyMedia, API_CODE.mediaCommon];
    this.resetMediaValidation(targetMedia);
  },
  methods: {
    ...mapActions({
      changeIsByMedia: "changeIsByMedia",
    }),
    ...mapMutations({
      updatePublicationFrom: `${types.PUBLICATION_FROM}`,
      updatePublicationUntil: `${types.PUBLICATION_UNTIL}`,
      validatePublicationFrom: `validation/${types.PUBLICATION_FROM}`,
      validatePublicationUntil: `validation/${types.PUBLICATION_UNTIL}`,
    }),
    setIsByMedia(value) {
      this.changeIsByMedia({ [this.keyNameFrom]: value });
      this.changeIsByMedia({ [this.KeyNameUntil]: value });
    },
    setPublicationDateFrom(mediaId, from) {
      this.updatePublicationFrom({ mediaId, from });
    },
    setPublicationDateUntil(mediaId, until) {
      this.updatePublicationUntil({ mediaId, until });
    },
    resetMediaValidation(targetMedia) {
      const validateFrom = this.validatePublicationFrom;
      const validateUntil = this.validatePublicationUntil;
      targetMedia.forEach((mediaId) => {
        const isValid = true;
        validateFrom({ mediaId, isValid });
        validateUntil({ mediaId, isValid });
      });
    },
    disabledFrom(mediaId) {
      const until = this.publicationUntil(mediaId);
      return disabledDateFrom(until);
    },
    disabledUntil(mediaId) {
      const from = this.publicationFrom(mediaId);
      return disabledDateUntil(from);
    },
    hasErrorUntil(mediaId) {
      const from = parseISO(this.publicationFrom(mediaId));
      const until = parseISO(this.publicationUntil(mediaId));
      return isBefore(until, from);
    },
    validateFrom(mediaId, isValid) {
      this.validatePublicationFrom({ mediaId, isValid });
      this.validateUntil(mediaId, true);
    },
    validateUntil(mediaId, isValid) {
      if (!isValid) {
        this.validatePublicationUntil({ mediaId, isValid });
        return;
      }

      if (this.requiredUntil(mediaId)) {
        const until = this.publicationUntil(mediaId);
        const isValidUntil = until ? !this.hasErrorUntil(mediaId) : false;
        this.validatePublicationUntil({ mediaId, isValid: isValidUntil });
      } else {
        const isValidUntil = !this.hasErrorUntil(mediaId);
        this.validatePublicationUntil({ mediaId, isValid: isValidUntil });
      }
    },
    isPublished(mediaId) {
      if (!this.targetId) return false;

      const isCommon = mediaId === API_CODE.mediaCommon;
      const isPublished = isCommon
        ? this.mediaIds.some((id) => this.$isPublished(id))
        : this.$isPublished(mediaId);
      return isPublished;
    },
    requiredUntil(mediaId) {
      if ([API_CODE.media.zexy, API_CODE.media.mynavi].includes(mediaId)) {
        return this.isRequired(this.KeyNameUntil, mediaId);
      }

      const selectedMw = this.mediaIds.includes(API_CODE.media.mw);
      const isContract = this.benefitType(API_CODE.mediaCommon) === BENEFIT_TYPE.contract;
      const applicationUntil = this.applicationUntil(API_CODE.media.mw);
      const validUntil = this.validUntil(API_CODE.media.mw);
      const needPublicationUntil = applicationUntil || validUntil;
      if (selectedMw && isContract && needPublicationUntil) {
        return true;
      }
      return this.isRequired(this.KeyNameUntil, mediaId);
    },
    optionLevelUntil(mediaId) {
      return this.requiredUntil(mediaId) ? 2 : 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.publication {
  display: flex;
  flex-wrap: wrap;
  gap: $GRID 0;

  .publication-item {
    display: flex;
    width: 100%;
    align-items: center;

    &__icon {
      margin-right: $SUB_GRID * 2;
      flex-shrink: 0;
    }

    &__title {
      font-size: 13px;
      color: $COLOR_KEY;
      margin-right: $SUB_GRID * 2;
      white-space: nowrap;
    }
  }
}
</style>
