<template>
  <article class="benefit-list">
    <h2 class="benefit-list__header">
      <span class="benefit-list__header-text">{{ headerText }}</span>
    </h2>
    <transition name="benefitTransition" tag="div">
      <section v-show="!adding" class="benefit-list__body">
        <list-head
          :show-hall-benefit-count="showHallBenefitCount"
          :fair-benefit-count="fairBenefitCount"
          :hall-benefit-count="hallBenefitCount"
        >
          <div class="add-actions">
            <slot name="actions" />
          </div>
        </list-head>
        <slot name="list" />
      </section>
    </transition>
    <transition name="benefitTransition" tag="div">
      <section v-if="adding" class="benefit-edit">
        <div class="benefit-edit__title">{{ addTitle }}</div>
        <slot name="edit" />
      </section>
    </transition>
  </article>
</template>

<script>
import ListHead from "@/components/benefit/benefit-list/ListHead.vue";

export default {
  components: {
    ListHead,
  },
  props: {
    headerText: {
      type: String,
      required: true,
    },
    showHallBenefitCount: {
      type: Boolean,
      default: false,
    },
    fairBenefitCount: {
      type: Number,
      required: true,
    },
    hallBenefitCount: {
      type: Number,
      required: true,
    },
    adding: {
      type: Boolean,
      default: false,
    },
    addTitle: {
      type: String,
      default: "特典 新規登録",
    },
  },
};
</script>

<style scoped lang="scss">
.benefit-list {
  position: relative;
  box-sizing: border-box;
  border-top: 1px solid #d6d6d6;

  &__header {
    border-bottom: 1px solid #e2e2e2;
    padding: 0 $GRID;
  }

  &__header-text {
    color: $COLOR_KEY;
    font-size: 16px;
    display: inline-block;
    padding: 11px 0;
    position: relative;

    &::before {
      background: $COLOR_KEY;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      top: -1px;
      width: 100%;
    }
  }

  &__body {
    padding: $GRID $GRID * 2;
    position: relative;

    @media screen and (max-width: $NAVI_WIDTH + $MAX_WIDTH + $IMAGE_WIDTH + $GRID * 3) {
      padding: $GRID;
    }
  }
}

.add-actions {
  display: flex;
  gap: $GRID;
  justify-content: center;
  flex-wrap: wrap;
}

.benefit-edit {
  width: calc(100% - #{$GRID});
  margin: ($GRID * 0.5) ($GRID * 0.5) 0;
  background-color: #fff;
  box-shadow: $DEPTH_LV_1;
  z-index: 10;

  &__title {
    padding: 14px 0;
    text-align: center;
    color: $COLOR_KEY;
    font-size: 16px;
    font-weight: bold;
  }
}

.benefitTransition-enter-active {
  transition: opacity 0.3s;
}
.benefitTransition-leave-active {
  transition: opacity 0.3s;
}
.benefitTransition-enter,
.benefitTransition-leave-to {
  opacity: 0;
}
</style>
