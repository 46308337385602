<template>
  <section class="benefit-detail">
    <benefit-unit-kind
      :group-type="benefit.group_type"
      :media-ids="benefit.media_ids"
      class="benefit-detail__item"
    />
    <p class="benefit-detail__type benefit-detail__item">{{ benefitType }}</p>
    <benefit-unit-image
      :group-type="benefit.group_type"
      :image-ids="benefit.image_id"
      class="benefit-detail__item"
    />
    <benefit-unit-title
      :title="benefit.title"
      :media-ids="benefit.media_ids"
      :media="master.media"
      class="benefit-detail__item"
    />
    <benefit-unit-text-info
      v-if="hasValue(benefit.description)"
      :text-info="benefit.description"
      :media-ids="benefit.media_ids"
      :media="master.media"
      label="説明文"
      class="benefit-detail__item"
    />
    <benefit-unit-text-info
      v-if="hasValue(benefit.notes)"
      :text-info="benefit.notes"
      :media-ids="benefit.media_ids"
      :media="master.media"
      class="benefit-detail__item"
    />
    <benefit-unit-publication-period
      :media-ids="mediaIdsPublicationPeriod"
      :period-from="benefit.publication_from"
      :period-until="benefit.publication_until"
      :media="master.media"
      :status-master="master.license_status"
      title="掲載期間"
      class="benefit-detail__item"
    />
    <benefit-unit-valid-period-hall
      :benefit="benefit"
      :status-master="master.license_status"
      class="benefit-detail__item"
    />
    <div class="benefit-detail__item benefit-detail__footer">
      <use-count
        v-if="editable"
        statusText="掲載"
        :count="publishedMediaIds.length"
        @click="showPublishedInfoModal = true"
      />
      <use-count
        v-if="editable"
        statusText="予約"
        :count="scheduledMediaIds.length"
        @click="showScheduledInfoModal = true"
      />
      <p v-if="isMwHighLighted" class="benefit-detail__extra-label">
        みんな特典ハイライト
      </p>
      <p v-if="isMyNaviLimited" class="benefit-detail__extra-label">
        マイナビ限定
      </p>
      <action-button
        v-if="editable"
        :is-disabled="hasFatalError"
        text="編集する"
        class="benefit-detail__button-edit"
        @clickHandler="$emit('edit')"
      />
    </div>
    <hall-benefit-menu
      v-if="editable"
      :is-disabled="hasFatalError"
      @menuCopyBenefit="$emit('copyBenefit')"
      @menuDeleteBenefit="$emit('deleteBenefit')"
    />
    <!-- 掲載中/予約中の媒体表示用モーダル -->
    <benefit-unit-linked-info-modal
      v-if="showPublishedInfoModal"
      title="掲載中の媒体"
      :media-list="mediaList(publishedMediaIds)"
      @close="showPublishedInfoModal = false"
    />
    <benefit-unit-linked-info-modal
      v-if="showScheduledInfoModal"
      title="予約中の媒体"
      :media-list="mediaList(scheduledMediaIds)"
      @close="showScheduledInfoModal = false"
    />
  </section>
</template>

<script>
import BenefitUnitKind from "@/components/benefit/presentation/BenefitUnitKind.vue";
import BenefitUnitImage from "@/components/benefit/presentation/BenefitUnitImage.vue";
import BenefitUnitTitle from "@/components/benefit/presentation/BenefitUnitTitle.vue";
import BenefitUnitTextInfo from "@/components/benefit/presentation/BenefitUnitTextInfo.vue";
import BenefitUnitPublicationPeriod from "@/components/benefit/presentation/BenefitUnitPublicationPeriod.vue";
import BenefitUnitLinkedInfoModal from "@/components/benefit/presentation/BenefitUnitLinkedInfoModal.vue";
import BenefitUnitValidPeriodHall from "@/components/benefit/presentation/BenefitUnitValidPeriodHall.vue";
import UseCount from "@/components/atoms/common/UseCount.vue";
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import HallBenefitMenu from "@/components/benefit/presentation/HallBenefitMenu.vue";

import API_CODE from "@/assets/common/ApiCode";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BenefitUnitKind,
    BenefitUnitImage,
    BenefitUnitTitle,
    BenefitUnitTextInfo,
    BenefitUnitPublicationPeriod,
    BenefitUnitLinkedInfoModal,
    BenefitUnitValidPeriodHall,
    UseCount,
    ActionButton,
    HallBenefitMenu,
  },
  data() {
    return {
      showPublishedInfoModal: false,
      showScheduledInfoModal: false,
    };
  },
  props: {
    benefit: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      master: "master",
      $benefitTypes: "benefitTypeOptions",
      onlyMedia: "rules/onlyMedia",
    }),
    benefitType() {
      const benefitTypes = this.$benefitTypes(this.benefit.group_type);
      const targetType = Object.values(this.benefit.type).find((type) => type);
      return (benefitTypes[targetType] || {}).name;
    },
    isMwHighLighted() {
      const mediaId = API_CODE.media.mw;
      return !!(this.benefit.is_highlighted || {})[mediaId];
    },
    isMyNaviLimited() {
      const mediaId = API_CODE.media.mynavi;
      return !!(this.benefit.is_media_only || {})[mediaId];
    },
    isPublished() {
      return this.publishedMediaIds.length > 0;
    },
    isScheduled() {
      return this.scheduledMediaIds.length > 0;
    },
    publishedMediaIds() {
      const publishedMediaIds = this.benefit.published_at.map(
        ({ media_id }) => media_id
      );
      return Array.from(new Set(publishedMediaIds));
    },
    scheduledMediaIds() {
      const scheduledMediaIds = this.benefit.scheduled_at.map(
        ({ media_id }) => media_id
      );
      return Array.from(new Set(scheduledMediaIds));
    },
    mediaIdsValidPeriod() {
      const onlyMedia = this.onlyMedia("valid_from", this.benefit.group_type);
      return this.benefit.media_ids
        .filter((mediaId) => onlyMedia.includes(mediaId))
        .sort((a, b) => a - b);
    },
    mediaIdsPublicationPeriod() {
      const onlyMedia = this.onlyMedia(
        "publication_from",
        this.benefit.group_type
      );
      return this.benefit.media_ids
        .filter((mediaId) => onlyMedia.includes(mediaId))
        .sort((a, b) => a - b);
    },
    hasFatalError() {
      return Object.values(this.benefit.link_statuses).some(
        (status) => status == API_CODE.linkStatus.fatalError
      );
    },
  },
  methods: {
    ...mapActions(["setSelectedData", "setTargetId"]),
    hasValue(targetObj) {
      if (!targetObj) return false;
      return Object.values(targetObj).some((value) => value !== null);
    },
    mediaList(mediaIds) {
      return mediaIds.map((mediaId) => this.master.media[mediaId].name);
    },
  },
};
</script>

<style scoped lang="scss">
.benefit-detail {
  @include Box();
  min-width: $GRID * 22;
  padding: $GRID;
  position: relative;

  &__type {
    color: $COLOR_KEY;
    font-size: 11px;
    text-decoration: underline;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    gap: $GRID $SUB_GRID;
  }

  &__extra-label {
    @include benefitExtraLabel();
    font-size: 10px;
  }

  &__button-edit {
    margin-left: auto;
  }

  &__item + &__item {
    margin-top: $GRID;
  }
}
</style>
