<template>
  <article class="common-info">
    <h1 class="common-info__header">共通情報</h1>
    <div class="common-info__item">
      <h2 class="common-info__item-header">利用条件</h2>
      <p class="common-info__item-input">
        <input-radio
          v-model.number="termType"
          :items="termTypes"
          :is-required="true"
          :display-block="true"
          @check="validateTermType"
        />
      </p>
    </div>
    <div class="common-info__item">
      <h2 class="common-info__item-header">備考</h2>
      <p class="common-info__item-input">
        <validating-textarea
          v-model="description"
          :is-full-size="true"
          :is-textarea="true"
          :rows="5"
          :is-required="false"
          :max-length="2000"
          @check="validateDescription"
        />
      </p>
    </div>
  </article>
</template>

<script>
import API_CODE from "@/assets/common/ApiCode";

import { mapActions, mapGetters, mapMutations } from "vuex";

import InputRadio from "@/components/benefit/inputs/InputRadio.vue";
import ValidatingTextarea from "@/components/molecules/common/ValidatingTextarea";

export default {
  components: {
    InputRadio,
    ValidatingTextarea,
  },
  props: {
    benefitType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      mediaId: API_CODE.media.mw,
      termTypes: {
        1: { id: 1, name: "１つ選択 - 「いずれかの特典をお選びください。」" },
        2: {
          id: 2,
          name: "複数選択可 - 「適用可能な特典を組み合わせてお選びいただけます。」",
        },
        3: {
          id: 3,
          name: "利用方法応相談 - 「特典の利用方法につきましてはご相談ください。」",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      $termType: "publishing/termType",
      $description: "publishing/description",
    }),
    termType: {
      get() {
        return this.$termType;
      },
      set(termType) {
        this.updateTermType(termType);
      },
    },
    description: {
      get() {
        return this.$description;
      },
      set(description) {
        this.updateDescription(description);
      },
    },
  },
  mounted() {
    const mediaId = this.mediaId;
    const benefitType = this.benefitType;
    this.setupCommonInfo({ mediaId, benefitType });
  },
  beforeUnmount() {
    this.resetCommonInfo();
    this.validateTermType(true);
    this.validateDescription(true);
  },
  methods: {
    ...mapActions({
      setupCommonInfo: "publishing/setupCommonInfo",
      resetCommonInfo: "publishing/resetCommonInfo",
    }),
    ...mapMutations({
      updateTermType: "publishing/termType",
      validateTermType: `publishing/validTermType`,
      updateDescription: "publishing/description",
      validateDescription: `publishing/validDescription`,
    }),
  },
};
</script>

<style lang="scss" scoped>
.common-info {
  &__header {
    color: $COLOR_KEY;
    font-size: 14px;
    font-weight: bold;
  }

  &__item {
    display: flex;
    margin: $GRID 0 0 $GRID;
  }

  &__item-header {
    font-size: 14px;
    font-weight: normal;
    width: $GRID * 4.5;
  }

  &__item-input {
    flex: 1;
  }
}
</style>
