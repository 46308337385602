<template>
  <benefit-form title="タイトルURL" :note="note" :option-level="isRequired ? 2 : 1">
    <input-item class="form-url-input">
      <input-url
        v-model="value"
        :is-required="isRequired"
        :is-valid="isValid"
        :max-length="maxCharacters(keyName, mediaId)"
        :show-length="true"
        @check="validateLinkUrl"
      />
      <p class="form-url-input__notice">
        ※「https://wedding.mynavi.jp」で始まるURLのみ入力できます
      </p>
    </input-item>
  </benefit-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import * as types from "@/store/benefit/mutationTypes";

import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import InputItem from "@/components/benefit/inputs/InputItem.vue";
import InputUrl from "@/components/molecules/common/InputUrl.vue";

export default {
  components: {
    BenefitForm,
    InputItem,
    InputUrl,
  },
  data() {
    return {
      keyName: "link_url",
      mediaId: API_CODE.media.mynavi,
      isValid: true,
    };
  },
  computed: {
    ...mapGetters({
      optionLevel: "optionLevel",
      needNote: "needNote",
      onlyMediaName: "onlyMediaName",
      isRequired: "requiredMynaviLink",
      maxCharacters: "rules/maxCharacters",
      linkUrl: "linkUrl",
    }),
    note() {
      if (this.needNote(this.keyName)) {
        return this.onlyMediaName(this.keyName);
      }
      return null;
    },
    value: {
      get() {
        return this.linkUrl(this.mediaId);
      },
      set({ url }) {
        const mediaId = this.mediaId;
        this.updateLinkUrl({ mediaId, url });
      },
    },
  },
  beforeUnmount() {
    this.validateLinkUrl(true);
  },
  methods: {
    ...mapMutations({
      updateLinkUrl: `${types.LINK_URL}`,
      $validateLinkUrl: `validation/${types.LINK_URL}`,
    }),
    validateLinkUrl({ isComplete }) {
      const url = this.value || "";
      const isValidDomain = url.startsWith("https://wedding.mynavi.jp");
      const isCompleteValid = isValidDomain && isComplete;

      if (this.isRequired) {
        this.isValid = isCompleteValid;
      } else {
        this.isValid = this.value ? isCompleteValid : true;
      }

      const mediaId = this.mediaId;
      const isValid = this.isValid;
      this.$validateLinkUrl({ mediaId, isValid });
    },
  },
};
</script>

<style scoped lang="scss">
.form-url-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;

  &__notice {
    color: $COLOR_TEXT;
    font-size: $FONT_SIZE_SS;
    margin-top: $SUB_GRID;
  }
}
</style>
