import { TagType } from "@/assets/tag/TagType";

import { getCookieAccount } from "@/lib/cookie";

import Repository from "./repository";

export default () => {
  const { hall_id, token, userable_id } = getCookieAccount();
  const hallId = hall_id || userable_id;
  const resource = "tags";
  const { instance, index, store, update, destroy } = Repository(`${hallId}/${resource}`, token);
  return {
    index,
    store,
    update,
    destroy,
    indexImageTags: () => index({ type: TagType.IMAGE }),
    storeImageTag: (names) => store({ names, type: TagType.IMAGE }),
    indexFairTags: () => index({ type: TagType.FAIR }),
    storeFairTag: (names) => store({ names, type: TagType.FAIR }),
    indexPlanTags: () => index({ type: TagType.PLAN }),
    storePlanTag: (names) => store({ names, type: TagType.PLAN }),
    updateOrder: (payload) => instance.put("order", payload),
  };
};
