import API_CODE from "@/assets/common/ApiCode";
import {
  PAGE_CONFIG,
  COMPANY_PAGE_CONFIG,
  ADMIN_PAGE_CONFIG,
  IMPORT_PAGE_CONFIG,
  DETAIL_PAGE_CONFIG,
  getSelectedHallId,
  deselectHall,
} from "@/assets/common/PageConfig.js";

import { removeCookieAccount, saveCookieAccount } from "@/lib/cookie";
import { movePage } from "@/lib/router";

import {
  authorize,
  deleteToken,
  indexPermissions,
  showAuthenticatedUser,
} from "@/repositories/authentication-api";
import useHallRepository from "@/repositories/hall";

import * as types from "@/store/common/mutationTypes";

const actions = {
  async fetchAccount({ commit }) {
    const { status, data } = await showAuthenticatedUser();
    const account = status === API_CODE.response.success ? data.data : {};
    commit("account", account);
  },
  async fetchHall({ commit }) {
    const { showUsage } = useHallRepository();
    const { status, data } = await showUsage();
    const isSuccess = status === API_CODE.response.success;
    const hall = isSuccess ? data.data : {};
    const master = isSuccess ? data.master : {};
    commit("hall", hall);
    commit("master", master);
  },

  /**
   * ログイン処理
   * @param {Object} payload { userable_id: 式場ID, name: 名前 }
   */
  async login({ commit }, payload) {
    const { userable_id, name } = payload;
    const { data, status } = await authorize(payload).catch(() => {
      commit(types.LOGOUT);
    });
    if (status === API_CODE.response.success) {
      commit(types.LOGIN, data);
      saveCookieAccount({
        name,
        userable_id,
        userable_type: data.type,
        token: data.token,
      });
    }
    return status;
  },
  /**
   * ログアウト処理
   */
  async logout({ commit }) {
    const response = await deleteToken();
    commit(types.LOGOUT);
    removeCookieAccount();
    return response && response.status === API_CODE.response.success;
  },
  /**
   * ログイン中のユーザー情報を取得し store に保存
   */
  async getLoginAccount({ getters, dispatch }) {
    await dispatch("fetchAccount");

    const { userable_type } = getters.account;
    const hallId = getSelectedHallId();
    if (userable_type !== "Hall" && !hallId) {
      return;
    }
    await dispatch("fetchHall");
  },

  /**
   * 現在のページがアクセス可能であるか確認
   * アクセスできない場合 top へ移動
   * 通常のページは getInitialData で実行する API がアクセス権限エラーになるため本処理は必要ない
   */
  async checkPagePermission({ getters, dispatch, commit }) {
    if (!getters.account) {
      await dispatch("getLoginAccount");
    }
    const params = { operation_target_hall_id: getSelectedHallId() };
    const { status, data } = await indexPermissions({ params });
    if (status !== API_CODE.response.success) {
      // 権限が取得できなかった場合は強制ログアウト
      dispatch("logout");
      return;
    }
    const permissions = data.data;
    commit("permissions", permissions);
    if (permissions && location.pathname !== "/") {
      let pages = PAGE_CONFIG.concat(IMPORT_PAGE_CONFIG);
      if (!getSelectedHallId()) {
        if (getters.isViewableOwnHall) {
          pages = COMPANY_PAGE_CONFIG;
        } else if (getters.isViewableAnyHall) {
          pages = ADMIN_PAGE_CONFIG;
        }
      }
      // アクセス制限の確認
      let path = location.pathname;
      if (path.length >= 2 && path.indexOf("/") === 0) path = path.substring(1); // 最初の '/' を削除
      path = path.split("/")[0];
      if (path in DETAIL_PAGE_CONFIG) {
        path = DETAIL_PAGE_CONFIG[path];
      }
      const page = pages.find((v) => v.dir == path); // 式場アカウントで親URLを実行すると undefined
      if (page && !page.permission) {
        // page.permission が設定していない時は権限チェックを行わない
      } else if (!page || !getters.isAuthorized(page.permission)) {
        if (page && IMPORT_PAGE_CONFIG.findIndex((v) => v.dir == page.dir) !== -1) {
          deselectHall(); // インポートツールのアクセス制限の場合は選択式場を解除（管理ページに移動）
        }
        if (movePage("/?permission", "/")) return; // top に移動
      }
    }
  },
};
export default actions;
