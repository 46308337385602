<template>
  <unit-menu :is-disabled="isDisabled" ref="menu">
    <template #menu>
      <menu-btn
        v-scroll-to="'#scrollTop'"
        text="コピーして編集する"
        icon="edit"
        @clickHandler="emit('menuCopyBenefit')"
      />
      <menu-btn text="削除する" icon="delete" @clickHandler="emit('menuDeleteBenefit')" />
    </template>
  </unit-menu>
</template>

<script>
import UnitMenu from "@/components/molecules/common/ListUnitMenu.vue";
import MenuBtn from "@/components/atoms/common/MenuButton.vue";

export default {
  components: {
    UnitMenu,
    MenuBtn,
  },
  props: {
    benefit: Object,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emit(event) {
      this.$refs.menu.changeIsShow();
      this.$emit(event);
    },
  },
};
</script>
