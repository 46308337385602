<template>
  <section class="unit-image-detail">
    <div class="image-name" :class="{ center: !image.name }">
      {{ imageName }}
    </div>
    <div class="image-file-name" v-if="image.name">
      {{ image.original_file_name }}
    </div>
    <div class="image-tags" :class="{ disabled: imageTags === null }">
      <i class="material-icons">local_offer</i>
      <span>{{ imageTags || "なし" }}</span>
    </div>
    <div class="image-linked-info">
      <use-count class="used-count" :count="usedData.length" :is-large="true" />
      <ul class="linked-media">
        <li
          class="linked-media__item"
          v-for="(_, mediaId) in image.media_image_id"
          :class="{ disabled: image.media.indexOf(Number(mediaId)) === -1 }"
          :key="mediaId"
        >
          <span>{{ media[mediaId].short_name }}</span>
        </li>
      </ul>
    </div>
    <div class="trim-list">
      <i class="material-icons">crop</i>
      <p
        class="trim-list__item fontNum"
        v-for="({ crop_type }, index) in image.trimming"
        :class="{ disabled: !existsTrimmedUrl(crop_type) }"
        :key="index"
      >
        <span>{{ cropType[crop_type] }}</span>
      </p>
    </div>
    <div class="image-size fontNum">
      <p class="image-size__width-and-height">
        <i class="material-icons">photo</i>
        <span>{{ image.width }} x {{ image.height }}</span>
      </p>
      <p class="image-size__large-trim" :class="{ disabled: !existsLargeTrim }">ウエパ対応</p>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import UseCount from "@/components/atoms/common/UseCount.vue";

export default {
  components: {
    UseCount,
  },
  props: {
    image: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      tagsMaster: "tagsMaster",
      media: "media",
      cropType: "cropType",
    }),
    imageName() {
      return this.image.name || this.image.original_file_name;
    },
    imageTags() {
      if (!this.image.tags) {
        return [];
      }

      const tags = Object.values(this.image.tags).map((tagId) => this.tagsMaster[tagId]);
      return tags.length > 0 ? tags.join(",") : null;
    },
    usedData() {
      return this.image.used_at ? Object.values(this.image.used_at).flat() : [];
    },
    existsLargeTrim() {
      return !!this.image.urls["1080"];
    },
  },
  methods: {
    existsTrimmedUrl(cropType) {
      if (!this.image.urls) {
        return false;
      }
      const fetchedCropType = this.cropType[cropType];
      return !!fetchedCropType ? !!this.image.urls[fetchedCropType] : false;
    },
  },
};
</script>

<style scoped lang="scss">
@mixin detailItem() {
  margin: $GRID * 0.5 0;
}

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin hasBorderBottom() {
  border-bottom: 1px solid $BG_COLOR_SUB;
  padding-bottom: $GRID * 0.5;
}

@mixin boxWithBorder() {
  align-items: center;
  border: 2px solid;
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  height: $GRID;
  justify-content: space-around;
  padding-top: 1px;

  &.disabled {
    border-color: #f7f7f7;
    color: $COLOR_DISABLE_TEXT;
  }
}

.unit-image-detail {
  color: $COLOR_KEY;
  font-size: 10px;
  min-height: $GRID * 14;
  padding: 0 $GRID $GRID;

  .material-icons {
    font-size: 14px;
  }

  .image-name {
    @include detailItem();
    @include ellipsis();
    font-size: 12px;
    font-weight: bold;
    height: $GRID;
    margin: 12px 0 4px;
    position: relative;

    &.center {
      height: $GRID * 2;
      line-height: $GRID * 2;
      margin: 12px 0;
    }
  }

  .image-file-name {
    @include detailItem();
    @include ellipsis();
    color: $COLOR_SUB_TEXT;
    height: $GRID;
    margin: 4px 0 12px;
  }

  .image-tags {
    @include detailItem();
    @include ellipsis();
    background: $BG_COLOR_SUB;
    border-radius: 6px;
    padding: 0 $GRID * 0.25;

    &.disabled {
      color: $COLOR_DISABLE_TEXT;
    }

    .material-icons {
      padding: 2px 2px 0 0;
    }
  }

  .image-linked-info {
    @include detailItem();
    align-items: center;
    display: flex;
    gap: $GRID * 0.5;

    .used-count {
      pointer-events: none;
    }

    .linked-media {
      list-style-type: none;

      &__item {
        @include boxWithBorder();
        margin: 1px 0;
        width: $GRID * 5;
      }
    }
  }

  .trim-list {
    @include detailItem();
    @include hasBorderBottom();
    align-items: center;
    display: flex;
    font-size: 12px;

    &__item,
    .material-icons {
      margin-right: $GRID * 0.5;
    }

    &__item {
      @include boxWithBorder();
      letter-spacing: 0.1em;
      text-indent: 0.1em;
      width: $GRID * 3;
    }
  }

  .image-size {
    @include detailItem();
    @include hasBorderBottom();
    display: flex;

    .material-icons {
      margin-right: $GRID * 0.5;
    }

    &__width-and-height {
      font-size: 12px;
      margin-right: $GRID * 0.5;
      min-width: $GRID * 5.25;
    }

    &__large-trim {
      @include boxWithBorder();
      width: $GRID * 4;
    }
  }
}
</style>
