import API_CODE from "@/assets/common/ApiCode";

import getters from "@/store/contact/getters";
import mutations from "@/store/contact/mutations";
import actions from "@/store/contact/actions";

import modalModule from "@/store/modal";

const state = {
  isShow: false,
  init: {
    contact_type: null,
    service_name: null,
    resource_identifier: "",
    media_names: [],
    content: "",
    customer_name: "",
    customer_tel: null,
    customer_mail: "",
    customer_time: null,
  },
  data: {
    contact_type: null,
    service_name: null,
    resource_identifier: "",
    media_names: [],
    content: "",
    customer_name: "",
    customer_tel: null,
    customer_mail: "",
    customer_time: null,
  },
  master: {
    contact_type: {
      1: "お問い合わせ",
      2: "ご意見・ご要望",
    },
    service_name: {
      1: "フェア・スケジュール",
      2: "プラン",
      3: "画像",
      4: "フェア特典・式場特典",
      5: "新着情報",
      6: "その他",
    },
    media_names: {
      [API_CODE.media.zexy]: "ゼクシィ",
      [API_CODE.media.mynavi]: "マイナビ",
      [API_CODE.media.ksm]: "婚スタ",
      [API_CODE.media.mw]: "みんな",
      [API_CODE.media.wp]: "ウエパ",
      [API_CODE.media.hanayume]: "ハナユメ",
      [API_CODE.media.homepage]: "コレモネっと",
    },
  },
  validation: {
    requiredItems: [], // 必須項目
    completedRequiredItems: [], // 入力済み必須項目
    validItems: [], // 入力済み項目
    itemsHasError: [], // エラーのある項目
  },
  rules: {
    contact_type: {
      is_required: true,
    },
    service_name: {
      is_required: true,
    },
    resource_identifier: {
      is_required: false,
      max_characters: 100,
    },
    media_names: {
      is_required: false,
    },
    content: {
      is_required: true,
      max_characters: 1000,
    },
    customer_name: {
      is_required: true,
      max_characters: 30,
    },
    customer_tel: {
      is_required: true,
    },
    customer_mail: {
      is_required: true,
      max_characters: 100,
    },
    customer_time: {
      is_required: false,
      max_characters: 100,
    },
  },
};

const contactModule = {
  namespaced: true,
  modules: {
    modal: modalModule,
  },
  state,
  getters,
  mutations,
  actions,
};

export default contactModule;
