<template>
  <section :id="`b-${data.id}`" class="hall-benefit" @click="$emit('onClick')">
    <unit-head :id="data.id" :date="data.updated_at" :is-small-id="true" />
    <transition v-if="isEdit" name="benefit-animation" tag="div">
      <hall-benefit-edit class="benefit-edit" @close="editBenefit(null)" />
    </transition>
    <transition v-else name="benefit-animation" tag="div">
      <hall-benefit-detail
        class="benefit-detail"
        :benefit="data"
        :editable="editable"
        :class="{ selectable, selected }"
        @edit="editBenefit(data.id)"
        @copyBenefit="copyBenefit"
        @deleteBenefit="deleteBenefit"
      />
    </transition>
  </section>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import { mapActions, mapState } from "vuex";

import UnitHead from "@/components/molecules/common/ListUnitHead.vue";
import HallBenefitDetail from "@/components/benefit/benefit-list/HallBenefitDetail.vue";
import HallBenefitEdit from "@/components/benefit/benefit-list/HallBenefitEdit.vue";

export default {
  components: {
    UnitHead,
    HallBenefitDetail,
    HallBenefitEdit,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["anchor"]),
  },
  mounted() {
    if (this.anchor.hash && this.anchor.id == this.data.id) {
      VueScrollTo.scrollTo(this.anchor.hash);
    }
  },
  methods: {
    ...mapActions({
      copyBenefitData: "copyBenefitData",
      setSelectedData: "setSelectedData",
      setTargetId: "setTargetId",
      showConfirm: "modal/showConfirmModal",
    }),
    editBenefit(id) {
      // データ初期化
      this.setSelectedData(this.data);

      // 編集ID保存
      this.setTargetId(this.data.id);
      VueScrollTo.scrollTo("#b-" + this.data.id);
      this.$emit("updateEditId", id);
    },
    copyBenefit() {
      this.$emit("updateEditId", null);
      // データ初期化
      this.copyBenefitData({
        data: this.data,
        group_type: this.data.group_type,
      });
      // 編集IDを初期化
      this.setTargetId(null);
      // 追加モーダルを表示
      this.$emit("showCopyEditModal");
    },
    deleteBenefit() {
      const payload = {
        targetId: this.data.id,
        type: "benefit",
        status: 1,
        param: {
          group_type: this.data.group_type,
        },
        actionName: "deleteData",
      };
      this.showConfirm(payload);
    },
  },
};
</script>

<style scoped lang="scss">
.hall-benefit {
  transition: all 0.3s;

  .benefit-edit {
    background: $BG_COLOR_BOX;
    box-shadow: $DEPTH_LV_2;
  }

  .benefit-detail {
    &.selectable {
      cursor: pointer;

      &:hover {
        box-shadow: $DEPTH_LV_2;
        transform: scale(1.015);
        transition: transform 160ms ease-out;
      }
    }

    &.selected {
      &::after {
        border: 2px solid #5c5;
        border-radius: $BOX_RADIUS_FOR_LARGE_BOX;
        content: "";
        height: 100%;
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
        width: 100%;
      }

      &:hover {
        &::after {
          opacity: 0.7;
        }
      }
    }
  }
}
.benefit-animation {
  &-enter-active {
    transition: opacity 0.3s;
  }
  &-enter-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
