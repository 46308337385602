import { createSwitchResourceInstance } from "./repository";

export default () => {
  const resource = "/jobs";
  return {
    indexIncompletedJobs: (params) =>
      createSwitchResourceInstance(`${resource}/incomplete`, "get", { params }),
    indexFailedLogs: () => createSwitchResourceInstance(`${resource}/failed-logs`, "get"),
    retry: (jobId) => createSwitchResourceInstance(`${resource}/${jobId}/retry`, "post"),
  };
};
