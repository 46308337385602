<template>
  <benefit-unit-period
    title="掲載期間"
    :period-list="periodList"
    :is-by-media="isByMedia"
    :common-period="commonPeriod"
  />
</template>

<script>
import { mapGetters } from "vuex";

import { isBefore, isEqual, parseISO, startOfToday, addDays } from "date-fns";

import API_CODE from "@/assets/common/ApiCode";

import periodText from "@/components/benefit/helper/period-text";
import isByMedia from "@/components/benefit/helper/is-by-media";

import BenefitUnitPeriod from "@/components/benefit/presentation/BenefitUnitPeriod.vue";

export default {
  components: {
    BenefitUnitPeriod,
  },
  props: {
    mediaIds: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    media: {
      type: Object,
      default: () => ({}),
    },
    statusMaster: {
      type: Object,
      default: () => ({}),
    },
    periodFrom: {
      type: [Object, Array],
      default: () => ({}),
    },
    periodUntil: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(["setting"]),
    periodList() {
      return this.mediaIds.map((mediaId) => ({
        mediaId,
        mediaName: this.media[mediaId].short_name,
        value: this.periodText(mediaId) || null,
        status: this.status(mediaId),
      }));
    },
    isByMedia() {
      const fromIsByMedia = isByMedia(this.periodFrom);
      const untilIsByMedia = isByMedia(this.periodUntil);
      return fromIsByMedia || untilIsByMedia;
    },
    commonPeriod() {
      return {
        value: this.periodText(),
        status: this.status(),
      };
    },
  },
  methods: {
    periodText(mediaId = API_CODE.mediaCommon) {
      // 終了・終了間近の場合はステータスを取得する
      const status = this.status(mediaId);
      const statusText = status ? `${this.statusMaster[status]}: ` : "";

      const from = this.periodFrom;
      const until = this.periodUntil;
      const text = periodText(from, until, mediaId);
      return text ? `${statusText}${text}` : null;
    },
    status(mediaId = API_CODE.mediaCommon) {
      const until = this.periodUntil[mediaId];
      const untilDate = parseISO(until);
      const today = startOfToday();
      const diffInDays = this.setting.hall_benefit_publication_date_period;
      const soonDate = addDays(today, diffInDays);

      const isExpired = isBefore(untilDate, today);
      if (isExpired) {
        return 2;
      }

      const isSoon =
        isBefore(untilDate, soonDate) || isEqual(untilDate, soonDate);
      if (isSoon) {
        return 1;
      }

      return null;
    },
  },
};
</script>
