<template>
  <section class="hall-benefit">
    <div class="hall-benefit__body">
      <slot />
    </div>
    <div class="hall-benefit__footer">
      <unsaved-notification :is-show="hasChanged" />
      <action-button
        text="保存する"
        :is-disabled="!isAllValid"
        :is-save="true"
        @clickHandler="$emit('submit')"
      />
    </div>
  </section>
</template>

<script>
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import UnsavedNotification from "@/components/atoms/fair-schedule/UnsavedNotification.vue";

export default {
  components: {
    ActionButton,
    UnsavedNotification,
  },
  props: {
    hasChanged: {
      type: Boolean,
      default: false,
    },
    isAllValid: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
$footerHeight: calc(#{$GRID} * 4);
.hall-benefit {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: $GRID;

  &__body {
    height: calc(100% - #{$footerHeight});
    overflow-y: scroll;
    padding: $GRID $GRID ($GRID * 0.5);
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: $GRID;
    background-color: $BG_COLOR_BOX;
  }
}
</style>
