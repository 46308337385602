<template>
  <container-benefit-publish :has-changed="hasChanged(mediaId)" @submit="onClick">
    <h3 class="header">掲載中</h3>
    <draggable v-model="publishedBenefits" itemKey="id" handle=".benefit-unit" group="benefits">
      <template #item="{ element: benefit, index }">
        <benefit-publish-unit-wp
          :index="index + 1"
          :is-first="index === 0"
          :media-id="mediaId"
          :benefit-id="benefit.id"
          :type="benefit.type"
          :recommended="benefit.recommended"
          :image-id="benefit.image_id"
          :titles="benefit.title"
          :descriptions="benefit.description"
          :validFroms="benefit.valid_from"
          :validUntils="benefit.valid_until"
          :licenseStatuses="benefit.license_status"
          :published="true"
          class="benefit-unit"
          @click="removeBenefit(benefit)"
          @moveToUp="moveToUp(benefit.id)"
        />
      </template>
    </draggable>
    <template v-if="unpublishedBenefits.length > 0">
      <h3 class="header unpublished">
        <span class="with-line-text">未掲載</span>
        <span class="line" />
      </h3>
      <draggable v-model="unpublishedBenefits" itemKey="id" handle=".benefit-unit" group="benefits">
        <template #item="{ element: benefit, index }">
          <benefit-publish-unit-wp
            :index="index + 1"
            :media-id="mediaId"
            :benefit-id="benefit.id"
            :type="benefit.type"
            :recommended="benefit.recommended"
            :image-id="benefit.image_id"
            :titles="benefit.title"
            :descriptions="benefit.description"
            :validFroms="benefit.valid_from"
            :validUntils="benefit.valid_until"
            :licenseStatuses="benefit.license_status"
            :wide-label="true"
            class="benefit-unit"
            @click="addBenefit(benefit)"
          />
        </template>
      </draggable>
    </template>
  </container-benefit-publish>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import API_CODE from "@/assets/common/ApiCode";

import moveToUp from "@/lib/moveToUp";

import draggable from "vuedraggable";
import ContainerBenefitPublish from "@/components/benefit/hall-benefit-publish/ContainerBenefitPublish.vue";
import BenefitPublishUnitWp from "@/components/benefit/hall-benefit-publish/wp-setting/BenefitPublishUnitWp.vue";

export default {
  components: {
    draggable,
    ContainerBenefitPublish,
    BenefitPublishUnitWp,
  },
  data() {
    return {
      mediaId: API_CODE.media.wp,
      publishedBenefits: [],
      unpublishedBenefits: [],
      originIds: [],
    };
  },
  computed: {
    ...mapGetters({
      benefits: "wpBenefits",
      hasChanged: "publishing/hasChanged",
    }),
    publishedIds() {
      return this.publishedBenefits.map(({ id }) => id);
    },
    unpublishedIds() {
      return this.unpublishedBenefits.map(({ id }) => id);
    },
  },
  mounted() {
    const mediaId = this.mediaId;
    this.publishedBenefits = this.benefits
      .filter(({ status }) => status === API_CODE.status.public)
      .sort((a, b) => a.sort[mediaId] - b.sort[mediaId]);
    this.unpublishedBenefits = this.benefits.filter(({ id }) => !this.publishedIds.includes(id));
    this.originIds = [...this.publishedIds];
  },
  beforeUnmount() {
    this.updateHasChangedWp(false);
  },
  watch: {
    publishedIds(newValue) {
      this.updateHasChangedWp(newValue.join("") !== this.originIds.join(""));
    },
  },
  methods: {
    ...mapActions({
      putWpBenefitsInBulk: "putWpBenefitsInBulk",
    }),
    ...mapMutations({
      updateHasChangedWp: "publishing/hasChangedWp",
    }),
    removeBenefit(benefit) {
      this.publishedBenefits = this.publishedBenefits.filter(({ id }) => id !== benefit.id);
      this.unpublishedBenefits.push(benefit);
    },
    addBenefit(benefit) {
      this.unpublishedBenefits = this.unpublishedBenefits.filter(({ id }) => id !== benefit.id);
      this.publishedBenefits.push(benefit);
    },
    moveToUp(benefitId) {
      this.publishedBenefits = moveToUp(this.publishedBenefits, benefitId);
    },
    async onClick() {
      const mediaId = this.mediaId;
      // ウエパの特典を一括更新
      const succeed = await this.putWpBenefitsInBulk({
        mediaId,
        publishedIds: this.publishedIds,
        unpublishedIds: this.unpublishedIds,
      });
      if (succeed) {
        this.originIds = [...this.publishedIds];
        this.updateHasChangedWp(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  margin-bottom: $GRID;
  color: $COLOR_KEY;
  font-size: 14px;
  line-height: 14px;

  &.unpublished {
    margin-top: $GRID;
  }

  .with-line-text {
    padding-right: $SUB_GRID;
    background-color: #fff;
    z-index: inherit;
  }

  .line {
    flex: 1;
    height: 2px;
    background-color: #eee;
  }
}

.benefit-unit {
  padding-left: $GRID * 0.5;
  cursor: pointer;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  gap: 2px ($GRID * 0.5);
}

.benefit-unit + .benefit-unit {
  margin-top: $GRID;
}
</style>
