import axios from "axios";

import API_CODE from "@/assets/common/ApiCode.js";
import { deselectHall, IMPORT_PAGE_CONFIG } from "@/assets/common/PageConfig.js";
import { getServerConfig } from "@/assets/common/ServerConfig.js";

import { getCookieAccount } from "@/lib/cookie";
import modal from "@/lib/modal";
import { movePage } from "@/lib/router";

import ModalError from "@/components/modals/ModalError.vue";

const NOT_AUTH_API = [
  "/authenticate",
  "/mail-authenticate",
  "/media-accounts/connect",
];

const isNotAuthApi = (url) => NOT_AUTH_API.some((v) => url.search(v) === 0);

const isImportPage = (path) =>
  IMPORT_PAGE_CONFIG.findIndex((v) => path === v.dir || path === "/" + v.dir) !== -1;

const checkResponseStatus = (response) => {
  if (!response) return;
  if (response.response) response = response.response; // エラーなど
  switch (response.status) {
    case API_CODE.response.unauthorized:
      let url = response.config.url;
      const baseUrl = response.config.baseURL;
      const hasBaseUrl = url.indexOf(baseUrl) !== -1;
      url = hasBaseUrl ? url.slice(baseUrl.length) : url;
      if (!isNotAuthApi(url)) {
        movePage("/login?unauthorized", "/login");
      }
      break;
    case API_CODE.response.accessDenied:
      if (isImportPage(location.pathname)) deselectHall();
      movePage("/?permission", "/");
      break;
    case API_CODE.response.gatewayTimeout:
      // GET時のみエラー表示（POST、PUT等は更新中表示の解除が必要なので各処理のエラーハンドリングに任せる）
      if (response.config.method != "get") break;
      const elementId = "modalErrorGatewayTimeout";
      // エラー表示済みなら新たに表示はしない
      if (document.getElementById(elementId)) break;
      const props = { text: "データの取得に失敗しました　時間をおいて再度お試しください" };
      modal(ModalError, props, null, elementId);
      throw response;
    default:
      break;
  }
};

const responseInterceptors = [
  (response) => {
    checkResponseStatus(response);
    return response;
  },
  (error) => {
    const e = error && error.response ? error.response : error;
    checkResponseStatus(error);
    return e;
  },
];

const getResource = (apiUrl) => {
  const { userable_type, hall_id, userable_id } = getCookieAccount();
  if (userable_type === "Hall" || hall_id !== null) {
    const hallId = hall_id || userable_id;
    return `/${hallId}${apiUrl}`;
  }
  return userable_type === "Company" ? `/companies/${userable_id}${apiUrl}` : `${apiUrl}`;
};

export const createAxiosInstance = (token) => {
  const defaultHeaders = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const headers = token ? { ...defaultHeaders, Authorization: `Bearer ${token}` } : defaultHeaders;
  const baseURL = getServerConfig().apiBaseUrl;
  const withCredentials = true;
  const instance = axios.create({ headers, baseURL, withCredentials });
  instance.interceptors.response.use(...responseInterceptors);
  return instance;
};

export const createSwitchResourceInstance = (endpoint, method, payload = null) => {
  const resource = getResource(endpoint);
  const { token } = getCookieAccount();
  const instance = createAxiosInstance(token);
  return instance[method](resource, payload);
};

export default (resource, token) => {
  const instance = createAxiosInstance(token);
  const baseUrl = `${instance.defaults.baseURL}/${resource}`;
  instance.defaults.baseURL = baseUrl;
  return {
    instance,
    index: (params = {}) => instance.get("", { params }),
    store: (payload) => instance.post(null, payload),
    show: (id) => instance.get(`${id}`),
    update: (id, payload) => instance.put(`${id}`, payload),
    patch: (id, payload) => instance.patch(`${id}`, payload),
    destroy: (id) => instance.delete(`${id}`),
  };
};
