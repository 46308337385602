<template>
  <benefit-form
    title="適用期間"
    :note="note"
    :option-level="optionLevel([keyName.applicationFrom])"
  >
    <input-item class="period">
      <div class="period-item">
        <span class="period-item__title">申込開始</span>
        <datepicker
          v-model="applicationFrom"
          :is-required="isRequired(keyName.applicationFrom)"
          :has-error="false"
          :disabled-dates="disabledFrom(applicationUntil)"
        />
      </div>
      <div class="period-item">
        <span class="period-item__title">申込終了</span>
        <datepicker
          v-model="applicationUntil"
          :is-required="isRequired(keyName.applicationUntil)"
          :has-error="hasErrorApplicationUntil"
          :disabled-dates="disabledUntil(applicationFrom)"
          @check="validateApplicationUntil"
        />
      </div>
      <div class="period-item">
        <span class="period-item__title">実施開始</span>
        <datepicker
          v-model="periodFrom"
          :is-required="isRequired(keyName.applicationFrom)"
          :has-error="false"
          :disabled-dates="disabledFrom(periodUntil)"
        />
      </div>
      <div class="period-item">
        <span class="period-item__title">実施終了</span>
        <datepicker
          v-model="periodUntil"
          :is-required="isRequired(keyName.applicationUntil)"
          :has-error="hasErrorPeriodUntil"
          :disabled-dates="disabledUntil(periodFrom)"
          @check="validatePeriodUntil"
        />
      </div>
    </input-item>
  </benefit-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { isBefore, parseISO } from "date-fns";

import API_CODE from "@/assets/common/ApiCode";

import * as types from "@/store/benefit/mutationTypes";

import { disabledDateFrom, disabledDateUntil } from "@/lib/date";

import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import InputItem from "@/components/benefit/inputs/InputItem.vue";
import Datepicker from "@/components/plan-detail/inputs/Datepicker.vue";

export default {
  components: {
    BenefitForm,
    InputItem,
    Datepicker,
  },
  data() {
    return {
      mediaId: API_CODE.media.mw,
      keyName: {
        applicationFrom: "application_from",
        applicationUntil: "application_until",
        periodFrom: "valid_from",
        periodUntil: "valid_until",
      },
    };
  },
  computed: {
    ...mapGetters({
      isRequired: "rules/isRequired",
      $onlyMedia: "rules/onlyMedia",
      onlyMediaName: "onlyMediaName",
      optionLevel: "optionLevel",
      $note: "needNote",
      $applicationFrom: "applicationFrom",
      $applicationUntil: "applicationUntil",
      $periodFrom: "periodFrom",
      $periodUntil: "periodUntil",
    }),
    applicationFrom: {
      get() {
        return this.$applicationFrom(this.mediaId);
      },
      set(from) {
        const mediaId = this.mediaId;
        this.updateApplicationFrom({ mediaId, from });
      },
    },
    applicationUntil: {
      get() {
        return this.$applicationUntil(this.mediaId);
      },
      set(until) {
        const mediaId = this.mediaId;
        this.updateApplicationUntil({ mediaId, until });
      },
    },
    periodFrom: {
      get() {
        return this.$periodFrom(this.mediaId);
      },
      set(from) {
        const mediaId = this.mediaId;
        this.updatePeriodFrom({ mediaId, from });
      },
    },
    periodUntil: {
      get() {
        return this.$periodUntil(this.mediaId);
      },
      set(until) {
        const mediaId = this.mediaId;
        this.updatePeriodUntil({ mediaId, until });
      },
    },
    note() {
      if (this.$note(this.keyName.applicationFrom)) {
        return this.onlyMediaName(this.keyName.applicationFrom);
      }
      return null;
    },
    hasErrorApplicationUntil() {
      const from = parseISO(this.applicationFrom);
      const until = parseISO(this.applicationUntil);
      return isBefore(until, from);
    },
    hasErrorPeriodUntil() {
      const from = parseISO(this.periodFrom);
      const until = parseISO(this.periodUntil);
      return isBefore(until, from);
    },
  },
  methods: {
    ...mapMutations({
      updateApplicationFrom: `${types.APPLICATION_FROM}`,
      updateApplicationUntil: `${types.APPLICATION_UNTIL}`,
      updatePeriodFrom: `${types.PERIOD_FROM}`,
      updatePeriodUntil: `${types.PERIOD_UNTIL}`,
      $validatePeriodUntil: `validation/${types.PERIOD_UNTIL}`,
      $validateApplicationUntil: `validation/${types.APPLICATION_UNTIL}`,
    }),
    disabledFrom(until) {
      return disabledDateFrom(until);
    },
    disabledUntil(from) {
      return disabledDateUntil(from);
    },
    validatePeriodUntil(isValid) {
      const mediaId = this.mediaId;
      const payload = {
        mediaId,
        isValid: isValid && !this.hasErrorPeriodUntil,
      };
      this.$validatePeriodUntil(payload);
    },
    validateApplicationUntil(isValid) {
      const mediaId = this.mediaId;
      const payload = {
        mediaId,
        isValid: isValid && !this.hasErrorApplicationUntil,
      };
      this.$validateApplicationUntil(payload);
    },
  },
};
</script>
<style lang="scss" scoped>
.period {
  display: flex;
  flex-wrap: wrap;
  gap: $GRID 0;

  &-item {
    width: 50%;

    &__title {
      font-size: 10px;
      color: $COLOR_KEY;
      margin-right: $SUB_GRID;
      white-space: nowrap;
    }
  }
}
</style>
