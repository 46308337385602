<template>
  <benefit-form
    :option-level="optionLevel([keyName])"
    title="適用期間"
    note="※ゼクシィ用"
  >
    <input-item-period
      :media-id="mediaId"
      :need-by-media="false"
      class="input-item-period"
    />
  </benefit-form>
</template>

<script>
import API_CODE from "@/assets/common/ApiCode";

import { mapGetters } from "vuex";

import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import InputItemPeriod from "@/components/benefit/inputs/InputItemPeriod.vue";

export default {
  components: {
    BenefitForm,
    InputItemPeriod,
  },
  data() {
    return {
      keyName: "valid_from",
      mediaId: API_CODE.media.zexy,
    };
  },
  computed: {
    ...mapGetters({
      optionLevel: "optionLevel",
    }),
  },
};
</script>

<style scoped lang="scss">
.input-item-period {
  padding: $SUB_GRID * 2;
}
</style>
