<template>
  <benefit-form :note="note" :option-level="optionLevel([keyName])" title="割引">
    <input-radio
      v-model="value"
      :items="items"
      :is-required="isRequired(keyName)"
      class="discount-type-list"
      @check="sendValidation"
    />
  </benefit-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import * as types from "@/store/benefit/mutationTypes";

import API_CODE from "@/assets/common/ApiCode";

import BenefitForm from "@/components/benefit/presentation/BenefitForm.vue";
import InputRadio from "@/components/benefit/inputs/InputRadio.vue";

export default {
  components: {
    BenefitForm,
    InputRadio,
  },
  data() {
    return {
      keyName: "discount_type",
      mediaId: API_CODE.media.mw,
    };
  },
  computed: {
    value: {
      get() {
        return this.discountType(this.mediaId);
      },
      set(type) {
        const mediaId = this.mediaId;
        this.updateDiscountType({ mediaId, type });
      },
    },
    ...mapGetters({
      discountType: "discountType",
      discountTypeList: "discountTypeList",
      needNote: "needNote",
      onlyMediaName: "onlyMediaName",
      optionLevel: "optionLevel",
      isRequired: "rules/isRequired",
    }),
    items() {
      return Object.entries(this.discountTypeList).reduce((items, [key, name]) => {
        const id = Number(key);
        const value = { id, name };
        return { ...items, [id]: value };
      }, {});
    },
    note() {
      if (this.needNote(this.keyName)) {
        return this.onlyMediaName(this.keyName);
      }
      return null;
    },
  },
  beforeUnmount() {
    this.sendValidation(true);
  },
  methods: {
    ...mapMutations({
      updateDiscountType: `${types.DISCOUNT_TYPE}`,
      validateDiscountType: `validation/${types.DISCOUNT_TYPE}`,
    }),
    sendValidation(isValid) {
      this.validateDiscountType({ isValid, mediaId: this.mediaId });
    },
  },
};
</script>

<style lang="scss" scoped>
.discount-type-list {
  gap: $SUB_GRID ($GRID * 0.5);
}
</style>
