import API_CODE from "@/assets/common/ApiCode";
import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";

import deepCopy from "@/lib/deepCopy";

import useBenefitRepository from "@/repositories/benefit";
import useFairRepository from "@/repositories/fair";
import useHallRepository from "@/repositories/hall";
import useImageRepository from "@/repositories/image";

import { SET_FAIR_DATA as COMMIT_FAIR_MASTER } from "@/store/fair-master-list/mutationTypes";
import { SET_SCHEDULE_DATA as COMMIT_FAIR_SCHEDULE } from "@/store/fair-schedule/mutationTypes";
import { SET_BENEFIT_DATA as COMMIT_FAIR_BENEFIT } from "@/store/benefit/mutationTypes";

/*
  * 対象 state: (key、 dataStructure)
    * フェアマスタ: fair Array[object]
    * フェアスケジュール: fairs Object { fairId: object }
    * 画像: data Array[object]
    * フェア特典: benefit Object { 1: Array[object], 2: Array[object] }

  * adapterGetData function 取得した対象データの dataStructure を統一
    * format: { elementId: object }

  * adapterSetData function 更新する対象データの dataStructure に整形
*/

// タイマー実行間隔(sec)
const timeInterval = 60;

const sort = (a, b) => a._index - b._index;

const isElementSyncing = (element) => {
  return Object.values(element.link_statuses).some((status) => {
    return status === API_CODE.linkStatus.inSync;
  });
};

const checkableTargets = {
  // フェア一覧
  fair: {
    syncKey: "fairs",
    adapterGetData: (data) => {
      return Object.fromEntries(
        data.map((element, index) => {
          element._index = index;
          return [element.id, element];
        })
      );
    },
    adapterSetData: (data) => {
      return {
        fair: Object.values(data).sort(sort),
      };
    },
    adapterNewLinkStatus: (data) => {
      data = data.media_status;
      Object.keys(data).forEach((key) => {
        data[key] = data[key].link_status;
      });
      return data;
    },
    show: async (id) => {
      const { show } = useFairRepository();
      return await show(id);
    },
    mutation: COMMIT_FAIR_MASTER,
  },
  // フェアスケジュール
  fairs: {
    syncKey: "fairs",
    adapterGetData: (data) => data,
    adapterSetData: (data) => {
      return { data };
    },
    adapterNewLinkStatus: (data) => {
      data = data.media_status;
      Object.keys(data).forEach((key) => {
        data[key] = data[key].link_status;
      });
      return data;
    },
    show: async (id) => {
      const { show } = useFairRepository();
      return await show(id);
    },
    mutation: COMMIT_FAIR_SCHEDULE,
  },
  // 画像
  data: {
    syncKey: "images",
    adapterGetData: (data) => {
      return Object.fromEntries(
        data.map((element, index) => {
          element._index = index;
          return [element.id, element];
        })
      );
    },
    adapterSetData: (data) => {
      return Object.values(data).sort(sort);
    },
    adapterNewLinkStatus: (data) => {
      return data.data.link_statuses;
    },
    show: async (id) => {
      const { show } = useImageRepository();
      return await show(id);
    },
    mutation: "UPDATE_IMAGES",
  },
  // フェア特典
  benefit: {
    syncKey: "benefits",
    adapterGetData: (benefits) => {
      const flattedBenefits = Object.values(benefits).reduce(
        (benefits, currentBenefits) => [...benefits, ...currentBenefits],
        []
      );
      return Object.fromEntries(
        flattedBenefits.map((element, index) => {
          element._index = index;
          return [element.id, element];
        })
      );
    },
    adapterSetData: (data) => {
      const defaultBenefits = {
        [BENEFIT_GROUP_TYPE.fair]: [],
        [BENEFIT_GROUP_TYPE.wp]: [],
        [BENEFIT_GROUP_TYPE.hall]: [],
      };
      const benefit = Object.values(data)
        .sort(sort)
        .reduce((benefits, benefit) => {
          benefits[benefit.group_type].push(benefit);
          return benefits;
        }, defaultBenefits);
      return { benefit };
    },
    adapterNewLinkStatus: (data) => {
      return data.data.link_statuses;
    },
    show: async (id) => {
      const { show } = useBenefitRepository();
      return await show(id);
    },
    mutation: COMMIT_FAIR_BENEFIT,
  },
};

const statusChecker = {
  namespaced: true,
  state: {
    target: null,
    timer: null,
    isPause: false,
    skipIds: [],
  },
  getters: {
    target: (state) => {
      return state.target;
    },
    currentTarget: (_, getters) => {
      const target = getters["target"];
      return checkableTargets[target];
    },
    timer: (state) => {
      return state.timer;
    },
    isPause: (state) => {
      return state.isPause;
    },
    rootStateTarget: (state, getters, rootState) => {
      const target = getters["target"];
      if (!rootState.hasOwnProperty(target)) return;

      const rootStateTarget = deepCopy(rootState[target]);
      return getters["currentTarget"].adapterGetData(rootStateTarget);
    },
    rootStateElementsInSync: (state, getters) => {
      const rootStateTarget = getters["rootStateTarget"];
      return Object.values(rootStateTarget)
        .filter((element) => isElementSyncing(element))
        .map((element) => element.id);
    },
  },
  mutations: {
    SET_TARGET(state, payload) {
      state.target = payload;
    },
    SET_TIMER(state, payload) {
      state.timer = payload;
    },
    PAUSE_OR_RESTART_TIMER(state, { isPause }) {
      state.isPause = isPause;
    },
    ADD_SKIP_ID(state, { id }) {
      if (state.skipIds.includes(id)) return;
      state.skipIds.push(id);
    },
    RESET_STATUS_CHECKER(state) {
      state.target = null;
      state.timer = null;
      state.isPause = false;
      state.skipIds = [];
    },
  },
  actions: {
    async init({ getters, commit, dispatch }, { target }) {
      if (!checkableTargets.hasOwnProperty(target)) return;

      if (getters["target"] !== null) dispatch("reset");
      commit("SET_TARGET", target);
      dispatch("setTimer");
    },
    setTimer({ getters, commit, dispatch }) {
      const timer = setInterval(async () => {
        if (getters["isPause"]) return;

        const { showInSync } = useHallRepository();
        const response = await showInSync();
        if (response && response.status === API_CODE.response.success) {
          const data = response.data.data;
          const elementsInSync = data[getters["currentTarget"].syncKey];
          await dispatch("checkSyncStatus", { elementsInSync });
        }
      }, timeInterval * 1000);
      commit("SET_TIMER", timer);
    },
    /**
     * 連携中のフェアや特典などの状態に変化がないか確認する
     */
    async checkSyncStatus({ getters, dispatch }, { elementsInSync }) {
      const rootStateElementsInSync = getters["rootStateElementsInSync"];
      const noDiff = JSON.stringify(elementsInSync) === JSON.stringify(rootStateElementsInSync);
      if (noDiff) return;

      // 連携完了した対象エレメント
      const syncedElements = rootStateElementsInSync.filter((n) => !elementsInSync.includes(n));

      // 新規連携中の対象エレメント
      const newSyncingElements = elementsInSync.filter((n) => !rootStateElementsInSync.includes(n));

      await dispatch("updateTarget", {
        syncedElements,
        newSyncingElements,
      });
    },
    /**
     * フェアや特典などの連携状態を更新する
     */
    async updateTarget({ state, getters, commit }, { syncedElements, newSyncingElements }) {
      const rootStateTarget = getters["rootStateTarget"];
      const currentTarget = getters["currentTarget"];

      [...syncedElements, ...newSyncingElements].forEach(async (id) => {
        // store に存在していない対象（新規作成された対象）はスキップする
        const isNew = !rootStateTarget.hasOwnProperty(id);
        const skipped = state.skipIds.includes(id);
        if (isNew || skipped) return;

        // 連携状態に変更があった場合は、対象のデータを取得し直す
        const { data, status } = await currentTarget.show(id);
        if (status === API_CODE.response.success) {
          let element = rootStateTarget[id];
          element.link_statuses = currentTarget.adapterNewLinkStatus(data);
        }
        if (status === API_CODE.response.notFound) {
          commit("ADD_SKIP_ID", { id });
        }
      });

      const mutation = currentTarget.mutation;
      const payload = currentTarget.adapterSetData(rootStateTarget);
      commit(mutation, payload, { root: true });
    },
    pauseOrRestartTimer({ commit }, { isPause }) {
      commit("PAUSE_OR_RESTART_TIMER", { isPause });
    },
    reset({ getters, commit }) {
      clearInterval(getters["timer"]);
      commit("RESET_STATUS_CHECKER");
    },
  },
};

export default statusChecker;
