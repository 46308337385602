<template>
  <section class="valid-period">
    <div class="valid-period__header">
      <i class="material-icons">schedule</i>適用期間
    </div>
    <div class="valid-period__item">
      <h3 class="valid-period__media-name">ゼクシィ</h3>
      <p class="valid-period__media-value" :class="validPeriodClassZexy">
        {{ validPeriodZexy }}
      </p>
    </div>
    <div class="valid-period__item">
      <h3 class="valid-period__media-name">みんな</h3>
      <div class="valid-period__media-value">
        <p class="mw-period-item">
          <span class="period-name">申込期間</span>
          <span class="period-value" :class="applicationPeriodClassMw">{{
            applicationPeriodMw
          }}</span>
        </p>
        <p class="mw-period-item">
          <span class="period-name">実施期間</span>
          <span class="period-value" :class="validPeriodClassMw">{{
            validPeriodMW
          }}</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { isBefore, isEqual, parseISO, startOfToday, addDays } from "date-fns";

import API_CODE from "@/assets/common/ApiCode";

import periodText from "@/components/benefit/helper/period-text";

import { mapGetters } from "vuex";

export default {
  props: {
    benefit: {
      type: Object,
      default: null,
    },
    statusMaster: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(["setting"]),
    validPeriodZexy() {
      const mediaId = API_CODE.media.zexy;
      const { valid_from, valid_until, valid_period_text } = this.benefit;
      const validText = valid_period_text[mediaId];
      if (validText) return validText;

      const diffInDays = this.setting.hall_benefit_valid_date_period;
      return this.periodText(mediaId, valid_from, valid_until, diffInDays);
    },
    applicationPeriodMw() {
      const mediaId = API_CODE.media.mw;
      const { application_from, application_until } = this.benefit;
      const diffInDays = this.setting.hall_benefit_application_date_period;
      return this.periodText(
        mediaId,
        application_from,
        application_until,
        diffInDays
      );
    },
    validPeriodMW() {
      const mediaId = API_CODE.media.mw;
      const diffInDays = this.setting.hall_benefit_valid_date_period;
      const { valid_from, valid_until } = this.benefit;
      return this.periodText(mediaId, valid_from, valid_until, diffInDays);
    },
    validPeriodClassZexy() {
      const mediaId = API_CODE.media.zexy;
      const { valid_from, valid_until, valid_period_text } = this.benefit;
      const payload = {
        from: valid_from[mediaId],
        until: valid_until[mediaId],
        text: valid_period_text[mediaId],
        diffInDays: this.setting.hall_benefit_valid_date_period,
      };
      return this.periodClass(payload);
    },
    applicationPeriodClassMw() {
      const mediaId = API_CODE.media.mw;
      const { application_from, application_until } = this.benefit;
      const payload = {
        from: application_from[mediaId],
        until: application_until[mediaId],
        text: null,
        diffInDays: this.setting.hall_benefit_application_date_period,
      };
      return this.periodClass(payload);
    },
    validPeriodClassMw() {
      const mediaId = API_CODE.media.mw;
      const { valid_from, valid_until } = this.benefit;
      const payload = {
        from: valid_from[mediaId],
        until: valid_until[mediaId],
        text: null,
        diffInDays: this.setting.hall_benefit_valid_date_period,
      };
      return this.periodClass(payload);
    },
  },
  methods: {
    status(until, diffInDays) {
      const untilDate = parseISO(until);
      const today = startOfToday();
      const soonDate = addDays(today, diffInDays);

      const isExpired = isBefore(untilDate, today);
      if (isExpired) {
        return 2;
      }

      const isSoon =
        isBefore(untilDate, soonDate) || isEqual(untilDate, soonDate);
      if (isSoon) {
        return 1;
      }

      return null;
    },
    periodText(mediaId, from, until, diffInDays) {
      const status = this.status(until[mediaId], diffInDays);
      const statusText = status ? `${this.statusMaster[status]}: ` : "";
      const text = periodText(from, until, mediaId);
      return text ? `${statusText}${text}` : "―";
    },
    periodClass({ from, until, text, diffInDays }) {
      if (!from && !until && !text) {
        return "empty";
      }

      const status = this.status(until, diffInDays);
      return { soon: status === 1, expired: status === 2 };
    },
  },
};
</script>

<style lang="scss" scoped>
.valid-period {
  width: 100%;
  background: $BG_COLOR_SUB;
  border-radius: $BOX_RADIUS;
  color: $COLOR_SUB_TEXT;
  font-size: 11px;
  padding: ($GRID * 0.25) ($GRID * 0.5);

  &__header {
    margin-bottom: $GRID * 0.5;
    font-size: $FONT_SIZE_S;
    line-height: 14px;

    .material-icons {
      font-size: 14px;
      margin-right: $SUB_GRID;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    padding-left: $GRID * 0.5;
  }

  &__item + &__item {
    margin-top: $SUB_GRID;
  }

  &__media-name {
    width: $GRID * 5;
    color: $COLOR_SUB_LIGHT_TEXT;
    font-size: 11px;
    font-weight: bold;

    &:has(+ .soon),
    &:has(+ .expired) {
      align-self: center;
    }
  }

  &__media-value {
    flex: 1;
    align-self: flex-start;
  }

  .mw-period-item {
    display: flex;
    align-items: center;

    .period-name {
      width: $GRID * 4;
    }

    .period-value {
      flex: 1;
    }
  }

  .mw-period-item + .mw-period-item {
    margin-top: $SUB_GRID;
  }

  .empty {
    background: none;
    color: $COLOR_DISABLE_TEXT;
  }

  .soon,
  .expired {
    padding: 2px ($GRID * 0.5);
    border-radius: $BOX_RADIUS;
    color: #fff;
  }

  .soon {
    background-color: $BG_PERIOD_WARNING;
  }

  .expired {
    background-color: $BG_PERIOD_ALERT;
  }
}
</style>
