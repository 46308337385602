<template>
  <modal-header-window class="replace-modal" icon="swap_horiz" title="変更" @close="close">
    <template v-slot:content>
      <benefit-filter />
      <div class="select-form">
        <dl class="select-form-item">
          <dt class="title">
            <p class="title-main">{{ groupName }}</p>
          </dt>
          <dd class="contents">
            <fair-benefit-unit
              v-for="(item, index) in replaceBenefits"
              class="unit"
              mode="select"
              :data="item"
              :is-selected="isSelected(item.id)"
              :key="index"
              @setSelect="select"
            />
          </dd>
        </dl>
      </div>
    </template>
    <template v-slot:footer>
      <action-button
        text="選択する"
        :is-disabled="selectedBenefit == null"
        :is-save="true"
        @clickHandler="input"
      />
    </template>
  </modal-header-window>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ActionButton from "@/components/atoms/common/ActionButton.vue";
import FairBenefitUnit from "@/components/benefit/benefit-list/FairBenefitUnit.vue";
import BenefitFilter from "@/components/benefit/benefit-list/BenefitFilter";
import ModalHeaderWindow from "@/components/molecules/common/ModalHeaderWindow.vue";

export default {
  components: {
    ActionButton,
    FairBenefitUnit,
    BenefitFilter,
    ModalHeaderWindow,
  },
  props: {
    // 選択中の特典
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  data: function () {
    return {
      selectedData: null,
      groupName: "",
      // 一覧画面のフィルタ項目を一時的に保存する
      listQueries: {
        keyword: null,
        period: null,
        inUse: false,
        groupType: [],
        benefitType: [],
        media: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      benefits: "benefits",
      benefitItem: "benefitItem",
      master: "master",
      filterBenefits: "filter/filterBenefits",
      queries: "filter/queries",
      sort: "filter/sort",
    }),
    selectedBenefit() {
      if (this.selectedData == null) return this.modelValue;
      return this.selectedData;
    },
    replaceBenefits() {
      const groupType = this.modelValue.group_type;
      const benefits = this.filterBenefits(this.benefits[groupType]);

      // ひとつだけ掲載用の場合はそのまま返却する
      if (groupType == 1) return benefits;

      // 複数形採用の場合は特典種別でフィルタリングする
      const benefitTypes = Object.values(this.modelValue.type);
      const benefitType = benefitTypes.filter((type) => type !== null)[0] || null;
      return benefits.filter((benefit) => Object.values(benefit.type).includes(benefitType));
    },
  },
  created() {
    // 一覧のフィルター内容を一時的に保存する
    const queryValues = Object.entries(this.queries).map(([queryKey, { query }]) => [
      queryKey,
      query,
    ]);
    this.listQueries = Object.fromEntries(queryValues);
    this.listSort = this.sort.active;

    // フィルター内容を初期化
    this.resetFilter();
  },
  mounted() {
    this.selectedData = this.modelValue;

    // 特典グループ・種類
    const groupType = this.modelValue.group_type;
    const mediaNames = this.master.group_type[groupType].description;
    this.groupName = `${mediaNames}用`;
  },
  methods: {
    ...mapActions({
      resetFilter: "filter/resetFilter",
      updateFilter: "filter/updateFilter",
    }),
    isSelected(id) {
      return this.selectedBenefit.id === id;
    },
    select(value) {
      this.selectedData = value;
    },
    close() {
      const sort = this.listSort;
      this.updateFilter({ ...this.listQueries, sort });
      this.$emit("close");
    },
    input() {
      this.$emit("update:modelValue", this.selectedBenefit);
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">
.replace-modal {
  .select-form {
    @include Box();
    margin: $GRID 0;
    width: $GRID * 36;

    &-item {
      background: #fff;
      border-right: 1px solid #e2e2e2;
    }

    .title {
      align-items: baseline;
      border-bottom: 1px solid #e2e2e2;
      display: flex;
      padding: 0 $GRID;
      font-size: 12px;

      &-main {
        border-top: 2px solid $COLOR_KEY;
        color: $COLOR_KEY;
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        line-height: $GRID * 3;
      }
    }

    .contents {
      padding: $GRID;
    }
  }

  .unit {
    margin: 0;

    & + .unit {
      margin-top: $GRID;
    }
  }

  .tabs {
    margin: auto 0 $SUB_GRID auto;
  }
}
</style>
